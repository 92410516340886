<template lang="pug">
  include ../../mixins
  control-date-picker-common.datepicker.datepicker--variant_1(
    class-name="form__input form__input--light-3"
    label-class="form__label form__label--accent-1"
    :placeholder="filter.label"
    v-model="checked"
    :value="checked"
    :range="true"
    @update-date="updateDate"
    value-type="DD.MM.YYYY"
    format="DD.MM.YYYY"
    :name="filter.urlKey"
  )
</template>
<script>
export default {
  name: 'DateRangeWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(nval) {
        this.checked = nval || []
      },
    },

    filter: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.ckecked = nval || []
      },
    },
  },

  methods: {
    updateDate(data) {
      this.checked = data
      this.submit()
    },

    submit() {
      this.$emit('input', this.checked)
    },
  },
}
</script>
