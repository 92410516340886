export default {
  bind(node) {
    const mac = 1440
    const fhd = 1800
    const minHeight = 100

    const calcHeight = () => {
      const windowWidth = window.innerWidth
      const needAdaptiveHeigth = (windowWidth >= mac) && windowWidth < fhd
      const replica = node.querySelector('.js-replica')

      if (needAdaptiveHeigth) {
        const source = node.querySelector('.js-source')
        const sourceHeight = source.clientHeight

        if (sourceHeight > minHeight) {
          replica.style.height = `${sourceHeight}px`
        }
      } else {
        replica.style.height = ''
      }
    }

    window.onload = () => {
      calcHeight()
    }

    window.addEventListener('resize', () => {
      setTimeout(() => {
        calcHeight()
      }, 100)
    })
  },
}
