export function setTableWrapper() {
  const parents = document.querySelectorAll('.wysiwyg')
  if (parents) {
    parents.forEach(el => {
      let tables = el.querySelectorAll('table')
      tables = Array.from(tables)
      const zero = 0
      if (zero < tables.length) {
        tables.forEach(table => {
          const wrapper = document.createElement('div')
          table.parentNode.insertBefore(wrapper, table)
          wrapper.appendChild(table)
          wrapper.classList.add('table-wrapper')
        })
      }
    })
  }
}
