<template lang="pug">
  include ../mixins
  +b.pos--relative
    +b.ls-card--space_3sm.--space_3md-mac.--color_27
      ui-preloader(
        :class="{'is-active': isLoad}"
        loader-color="loader-wrapper__bg--accent-1"
        loader-class="loader--variant_absolute"
      )
      +b.ma.--bottom_2md
        +b.P.ls-text.--color_main.--weight_semi-bold.--size_md.--size_lg-custom-xl.ma--bottom_2sm(
        ) {{ _("Подписка на новости") }}
        +b.P.ls-text.--color_main.--size_3xs(
        ) {{ _("Вы будите получать подборку полезной информации по темам, которые Вас интересуют") }}
      validation-observer.form(
        tag="form"
        ref="observer"
        v-slot="{ errors }"
        @submit.prevent="validateBeforeSubmit"
        @keyup.enter="validateBeforeSubmit"
      )
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols--12.--6-sm.--4-md
            control-multiselect-common(
              v-model="formdata.category"
              rules="required"
              class="common-multiselect common-multiselect--white multiple-select"
              label-class="form__label form__label--accent-1"
              label='title'
              group-label='title'
              group-values="categories",
              name="category"
              :input-label="labels.category.label"
              :placeholder="labels.category.placeholder"
              :options="options.category"
              track-by="id"
              :group-select="true",
              :multiple="true"
              :required="true"
            )
              template(slot="noResult" slot-scope="{}")
                span {{ _("Элементы не найдены.") }}
              template(slot="noOptions" slot-scope="{}")
                span {{ _("Список пуст.") }}
              template(
                slot="selection"
                slot-scope="{ values, search }"
                v-if="values.length"
              )
                +b.SPAN.multiselect__single(v-if="values.length === options.category[0].categories.length")
                  | {{ _("Все темы") }}
                +b.SPAN.multiselect__single(v-else)
                  | {{ values.map(el => el.title).join(', ') }}
          +b.g-cell.g-cols--12.--6-sm.--4-md
            control-input-common(
              v-model="formdata.email"
              rules="custom_email|required"
              type="email"
              class-name="form__input form__input--white"
              label-class="form__label form__label--accent-1"
              name="email"
              :input-label="labels.email.label"
              :placeholder="labels.email.placeholder"
              :required="true"
            )
          +b.g-cell.g-cols--12.--6-sm.--3-md
            +b.ma--top_2sm-md
              bem-button(
                width="full"
                block="ls-button"
                appearance="yellow"
                :disabled="isLoad"
                @event="validateBeforeSubmit()"
              ) {{ _('Подписаться') }}
</template>

<script>
import FormMixin from '@/js/mixins/form.mixin.js'
import {
  newsCategoriesListResource,
  newsSubscribeResource,
} from '@/js/services/news.service.js'
import { mapState } from 'vuex'

export default {
  name: 'news-subscribe-form',

  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        email: '',
        category: [],
        isAll: false,
      },
      isLoad: false,
      options: {
        category: [{ categories: [] }],
      },
      labels: {
        category: {
          label: this._('Темы новостей'),
          placeholder: this._('Выберите тему'),
        },
        email: {
          label: this._('Email'),
          placeholder: this._('Введите Email'),
        },
      },
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (window.isAuthenticated) {
          this.setUserInfo(nval)
        }
      },
    },
  },

  created() {
    this.getCategories()
  },

  methods: {
    setUserInfo(data) {
      const { info } = data

      this.formdata.email = info.email
    },

    getCategories() {
      newsCategoriesListResource.execute().then(res => {
        const { data: { items } } = res

        this.options.category = [{
          title: this._('Все темы'),
          categories: items,
        }]

        this.selectAllCategories()
      })
    },

    selectAllCategories() {
      const [group] = this.options.category
      this.formdata.category = group.categories
    },

    async send(data) {
      this.isLoad = true

      const copyData = await this.prepareData({ ...data })

      const formdataLength = copyData.category.length
      const choicesLength = this.options.category[0].categories.length

      copyData.isAll = formdataLength === choicesLength

      return newsSubscribeResource.execute({}, copyData).then(res => {
        this.isLoad = false
        this.handleResponse({ res, modal: true })
      })
    },
  },
}
</script>
