<template lang="pug" functional>

ds-table-body(v-bind="data.attrs", v-on="data.on")
  slot(name="row", :fields="props.fields")
    ds-table-row(:relief="props.relief")
      template(v-for="field in props.fields")
        ds-table-cell(
          v-if="!!field.key.length", :key="field.key",
          :data-title="field.label", :appearance="`field-${field.key}`"
        )
          slot(
            name="cell-before", :fields="props.fields",
            :field="field" :item="props.item", :index="props.index"
          )
          slot(
            :name="`cell-${field.key}`", :fields="props.fields",
            :field="field" :item="props.item", :index="props.index"
          )
            slot(
              name="cell", :fields="props.fields",
              :field="field" :item="props.item", :index="props.index"
            )
              | {{ field.getValue(parent, props.item, field, props.index) }}
          slot(
            name="cell-after", :fields="props.fields",
            :field="field" :item="props.item", :index="props.index"
          )
        td(v-else)
      ds-table-cell(
        v-if="scopedSlots.action", data-title="_", appearance="action-cell"
      )
        slot(
          name="action", :fields="props.fields",
          :field="field", :item="props.item", :index="props.index"
        )
  slot(
    name="row-after", :fields="props.fields",
    :index="props.index", :item="props.item",
    :cells="props.fields && (props.fields.length + !!scopedSlots.action)"
  )

</template>

<script>

export default {
  name: 'vue-table-item',
  props: {
    fields: Array,
    item: {},
    index: Number,
    relief: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
