<template lang="pug">
include ../../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{_("Оставить отзыв")}}
  +b.ma--bottom_sm
    +b.i-steps
      +e.item(
        v-for="(item, index) in 3"
        :class="{'is-active': index === currentStep}"
      )
  keep-alive
    component(
      :is="steps[currentStep]"
      @form:prev="prevStep"
      @form:next="nextStep"
      @form:submit="complete"
    )
      template(slot="rules")
        +b.ma.--bottom_sm
          +b.ls-text--height_md
            +b.SPAN.ls-text.--color_main.--size_3xs.display_inline {{_('Отправляя форму, Вы соглашаетесь с')}}
            |&nbsp;
            +b.A.ls-link.--color_accent-1.--size_3xs.display_inline(
              target="_blank"
              :href="objectItem.privacy"
            ) {{_('политикой конфиденциальности')}}
            +b.SPAN.ls-text.--color_main.--size_3xs.display_inline &nbsp; {{_('и')}} &nbsp;
            +b.A.ls-link.--color_accent-1.--size_3xs.display_inline(
              target="_blank"
              :href="objectItem.rules"
            ) {{_('правилами сайта')}}
</template>

<script>
import {
  reviewCreateResource,
} from '@/js/services/reviews.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  name: 'review-create-modal',
  mixins: [FormMixin],
  components: {
    'privacy-rules': () => import('@/js/components/Forms/PrivacyRules'),
    'review-user-info': () => import('./UserInfo'),
    'review-school-info': () => import('./SchoolInfo'),
    'review-message': () => import('./Message'),
  },

  props: {
    objectItem: Object,
  },

  data() {
    return {
      currentStep: 0,
      steps: {
        0: 'review-user-info',
        1: 'review-school-info',
        2: 'review-message',
      },
      formdata: {},
    }
  },

  methods: {
    prevStep(data) {
      this.updateFormdata(data)
      this.currentStep = data.prevStep
    },

    nextStep(data) {
      this.currentStep = data.nextStep
      this.updateFormdata(data)
    },

    updateFormdata(data) {
      this.formdata = { ...this.formdata, ...data.data }
    },

    complete(data) {
      this.updateFormdata(data)
      this.send(this.formdata)
    },

    async send(data) {
      const copyData = await this.prepareData({ ...data })
      if (copyData.avatar.includes('base64')) {
        const [imageMimeType] = copyData.avatar.split(';')
        const [, imageType] = imageMimeType.split('/')
        copyData.avatar = `avatar.${imageType};${copyData.avatar}`
      } else {
        delete copyData.avatar
      }
      return reviewCreateResource.execute({}, copyData).then(res => {
        this.handleResponse({ res, modal: true })
        this.initialReceive()
      })
    },
  },
}
</script>
