<template>
  <filter-controller
    :receive="allProps.receive"
    :all="allProps.result"
    :pagination='pagination'
    :loading="allProps.loading"
    :value="value"
    :label="label"
    @input="handleFilter"
    :needSearch="needSearch"
    :searchHint="searchHint"
  />
</template>

<script>

import ViewMixin from './mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
}

</script>
