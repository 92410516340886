<template lang="pug">
  include ../mixins
  div
    +b.contact-square-container.u-display--hidden.--block-xxl
      +b.square-container.--flex_start
        +e.square.--message.--nb_angle1.--width_v1
          bem-text(
            tag="p"
            size="3xs"
            color="white"
          ) {{_('Привіт! Мене звати Тімофеева Катерина')}}
      +b.square-container.--flex_end
        +e.square.--message.--width_v4.--blue
          bem-text(
            tag="p"
            size="3xs"
            color="white"
          ) {{_('Заповнюй форму, та менеджер зв`яжется з тобою')}}
    +b.ls-card--space_3sm.--color_accent-1.--border_3sm-adaptive.pos--relative
      +b.modal-close--common.BUTTON(
        tabindex="-1"
        @click="$emit('close')"
      )
      contact-form(
        :object-item="objectItem"
        @close="$emit('close')"
      )
</template>

<script>
export default {
  name: 'contact-form-modal',
  props: {
    objectItem: Object,
  },
}
</script>
