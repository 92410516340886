/* eslint-disable */
import { renderSlim } from '@aspectus/vue-utils'
import { urlGetter } from '@utils/friendly'

const same = x => x

export default {
  name: 'router-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
    getData: {},
    isInfiniteContent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {},
      },
      isPopstate: false,
      isMounted: true,
    }
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.parameters = this.from({
        ...this.initial,
      })
      this.isPopstate = true
      this.$nextTick(() => {
        this.getData()
      })
      const timeout = 400
      setTimeout(() => {
        this.isPopstate = false
      }, timeout)
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parameters = this.from({
          ...this.initial,
        })
      },
    },
  },

  methods: {
    updateUrl(parameters) {
      const localParameters = JSON.parse(JSON.stringify(parameters))

      if (this.isPopstate) return

      if (this.isInfiniteContent) {
        delete localParameters.limit
        delete localParameters.offset
      }

      const query = this.to(localParameters)
      const zero = 0
      this.friendlyUrl.filters = query.filters
      const keys = Object.keys(this.friendlyUrl.filters)
      for (let x = 0; x <= keys.length; x++) {
        const f = this.friendlyUrl.filters[keys[x]]
        if (f !== undefined) {
          if (zero === f.length) {
            delete this.friendlyUrl.filters[keys[x]]
          }
        }
      }
      const order = {
        path: [
          'prefix',
        ],
        query: [
          'query',
        ],
      }
      const shift = 1
      const currentPage = Math.floor(
        localParameters.offset / localParameters.limit
      ) + shift
      const page = currentPage > shift ? currentPage : null
      const fullUrl = urlGetter([this.baseUrl], order, { ...localParameters, page })
      const prettyUrl = fullUrl === '?' ? '' : fullUrl
      history.pushState({ url: prettyUrl }, '', prettyUrl)
    },

    changeParameters(value) {
      if (this.isMounted) {
        this.isMounted = false
      } else {
        this.parameters = value
        this.updateUrl(this.parameters)
      }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag')
  },
}
