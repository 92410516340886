import ContactFormModal from './ContactFormModal'
import TeacherFormModal from './TeacherFormModal'
import CourseForm from './CourseForm'
import ReviewCreateModal from './Review/Create'
import NewsSubscribe from './NewsSubscribe'
import ContactForm from './ContactForm'
import StockForm from './StockForm'
import ContactPageForm from './ContactPageForm'

export default function install(Vue) {
  Vue.component(ContactFormModal.name, ContactFormModal)
  Vue.component(TeacherFormModal.name, TeacherFormModal)
  Vue.component(CourseForm.name, CourseForm)
  Vue.component('review-create-modal', ReviewCreateModal)
  Vue.component('news-subscribe-form', NewsSubscribe)
  Vue.component('contact-form', ContactForm)
  Vue.component('stock-form', StockForm)
  Vue.component('contact-page-form', ContactPageForm)
}
