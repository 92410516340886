import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (window.isAuthenticated) {
          this.setUserInfo(nval)
        }
      },
    },
  },

  methods: {
    setUserInfo(data) {
      const { info } = data
      this.formdata.phone = info.phone
      this.formdata.email = info.email
      if ('company' === info.typeUser) {
        this.formdata.firstName = info.firstNameContact
        this.formdata.lastName = info.lastNameContact
      } else {
        this.formdata.firstName = info.firstName
        this.formdata.lastName = info.lastName
      }
    },
  },
}
