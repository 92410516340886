import CheckboxWidget from '../Widgets/CheckboxWidget'
import RangeWidget from '../Widgets/RangeWidget'
import SearchWidget from '../Widgets/SearchWidget'
import RadioWidget from '../Widgets/RadioWidget'
import SelectWidget from '../Widgets/SelectWidget'
import MultiselectWidget from '../Widgets/MultiselectWidget'
import AutocompleteMultiselectWidget from '../Widgets/AutocompleteMultiselectWidget'
import DateRangeWidget from '../Widgets/DateRangeWidget'
import AutocompleteSelectWidget from '../Widgets/AutocompleteSelect'

const WIDGETS_MAP = {
  checkbox: CheckboxWidget,
  range: RangeWidget,
  radio: RadioWidget,
  search: SearchWidget,
  Select: SelectWidget,
  AutocompleteMultiSelect: AutocompleteMultiselectWidget,
  AutocompleteSelect: AutocompleteSelectWidget,
  MultiSelect: MultiselectWidget,
  DateRange: DateRangeWidget,
}

export default {
  widgetsMap: WIDGETS_MAP,

  computed: {
    parameters() {
      return {
        values: this.value,
      }
    },
  },

  methods: {
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values
      res[`${key}_after`] = min
      res[`${key}_before`] = max
      this.$emit('input', { ...this.value, ...res })
    },
  },
}
