import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:educational-literature'

export function createRoutes(options) {
  return [
    route('', Display, ROUTE_NAME, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee',
          'student',
        ], options),
      },
    }),
  ]
}
