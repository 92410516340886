import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')
const Create = () => import('./views/Create')
const ChildAdd = () => import('./views/ChildAdd')

const ROUTE_NAME = 'profile:users:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')
const DISPLAY_CHILD = p('display-child')
const ADD = p('add')
const CREATE = p('create')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([
          'manager',
        ], options),
      },
    }),
    route('create/', Create, ADD, {
      meta: {
        accessConfig: accessPermission([
          'manager',
        ], options),
      },
    }),
    route('create/:type/', ChildAdd, CREATE, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'manager',
        ], options),
      },
      props: true,
    }),
    route(':id/detail/', Display, DISPLAY, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'manager',
        ], options),
      },
      props: true,
    }),
    route(':type/:id/detail/', Display, DISPLAY_CHILD, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company',
        ], options),
      },
      props: true,
    }),
  ]
}
