import { route } from '@md/routing'
import { prefixLanguage } from '@utils/urls'

const Display = () => import('./views/Intro')
const Quiz = () => import('./views/Quiz')

const BASE_LINK = prefixLanguage('/taketests/')
const QUIZ_LINK = prefixLanguage('/taketests/quiz/')

const BASE_NAME = 'quiz:intro'
const QUIZ_NAME = 'quiz:view'

export function createRoutes() {
  return [
    route(BASE_LINK, Display, BASE_NAME),
    route(QUIZ_LINK, Quiz, QUIZ_NAME),
  ]
}

export function createRouter(options = {}) {
  const { Router, Vue } = options
  const router = new Router({
    mode: 'history',
    base: '/',
    routes: createRoutes({ Router, Vue }),
  })

  return router
}
