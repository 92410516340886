<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      :resource="additionalResource"
      v-slot="additionalProps"
    )
      component(
        :is="filterName"
        :value="filters"
        @input="changeFilters"
        :allProps="allProps",
        :pagination='pagination'
        :label='label',
        :needSearch="needSearch"
        :searchHint="searchHint"
        :additionalProps="additionalProps"
        :additionalResource="additionalResource"
        :totalItems="totalItems"
      )
</template>

<script>
import DefaultFilter from '@app/Filters'
import CabinetFilter from '@app/Filters/Cabinet'

export default {
  components: {
    CabinetFilter,
    DefaultFilter,
  },

  props: [
    'filters',
    'pagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'needSearch',
    'searchHint',
    'totalItems',
  ],

  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })
      this.$emit('update:filters', params)
    },
  },
}

</script>
