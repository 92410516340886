<template>
  <control-multiselect-common
    class="common-multiselect common-multiselect--light-3"
    label-class="form__label form__label--accent-1"
    v-model="checked"
    :label="label"
    :track-by="trackBy"
    :input-label="labelIsVisible ? filter.label : ''"
    :placeholder="placeholderIsVisible ? '' : filter.label"
    :searchable="true"
    :multiple="multiple"
    :internal-search="false"
    :options="localOptions"
    :show-no-results="false"
    @search-change="search"
    v-bind="$attrs"
    :rules="rules"
    @input="submit"
  >
    <template v-slot:noResult>{{ _('No elements found. Consider changing the search query.') }}</template>
    <template v-slot:noOptions>{{ _('Список пуст.') }}</template>
    <template v-slot:afterList>
      <div v-observe-visibility="setVisibility" v-if="hasNextPage"></div>
    </template>
  </control-multiselect-common>
</template>

<script>
export default {
  name: 'filter-autocomplete-multiselect-widget-component',
  props: [
    'value',
    'filter',
    'receive',
    'placeholder',
    'options',
    'result',
    'rules',
    'label',
    'trackBy',
    'labelIsVisible',
    'placeholderIsVisible',
    'multiple',
  ],

  data() {
    return {
      isLoading: false,
      isPagination: false,
      localOptions: [],
      query: null,
      checked: '',
      isMounted: true,
    }
  },

  created() {
    this.receive()
  },

  computed: {
    hasNextPage() {
      const page = this.result?.pagination?.nextPage
      return page
    },
  },

  watch: {
    result: {
      handler(nval) {
        this.setOptions(nval)
      },
    },

    value: {
      immediate: true,
      deep: true,
      handler(nval) {
        this.setInitialValue(nval && nval.id || nval)
      },
    },
  },

  methods: {
    setInitialValue(nval) {
      this.checked = this.normalizeFrom(nval)
    },

    submit() {
      this.$nextTick(() => {
        const val = this.normalizeTo(this.checked)
        this.$emit('input', val)
      })
    },

    normalizeTo(value) {
      if (!value) return []
      if (Array.isArray(value)) {
        return value.map(el => el.value || el.id)
      }
      return value.value || value
    },

    normalizeFrom(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.localOptions.filter(el => val.find(v => v === el.value || v === el.id))
      return checked
    },

    setOptions(nval) {
      const items = nval?.items
      if (this.isPagination) {
        this.localOptions.push(...items)
      } else {
        this.localOptions = items || []
      }
      if (this.isMounted) {
        // const val = this.value && this.value.id ? this.value.id : this.value
        // this.setInitialValue(val)
        if (this.value) {
          if (this.value.value || this.value.id) {
            this.prependOption()
          } else {
            this.setInitialValue(this.value)
          }
        }
        this.isMounted = false
      }
    },

    prependOption() {
      const option = this.localOptions.find(el => {
        if (el.value === this.value.value || el.id === this.value.id) {
          return el
        }
        return false
      })
      if (!option) {
        this.localOptions.unshift(this.value)
      }
      this.checked = this.value
    },

    search(query) {
      this.isPagination = false
      this.query = query
      this.receive({ search: query })
    },

    setVisibility(reached) {
      if (reached) {
        const { nextPage: page } = this.result.pagination
        this.isPagination = true
        this.receive({ page, search: this.query })
      }
    },
  },
}

</script>
