import {
  prefixAPI,
  baseAPI,
} from '@/js/resource/api'

export const TEACHER = {
  GET_TEACHER: baseAPI('/ajax-teachers-search/{;search*}/page/{page}/'),
  BECOME_TEACHER: prefixAPI('join_teacher/create/'),
  SCHOOL_LIST: prefixAPI('teacher/school/list/'),
  GROUP_LIST: prefixAPI('teacher/groups/list/{id}/'),
}

export const MANAGER = {
  GROUP_LIST: prefixAPI('group/list/manager/{id}/'),
}

export const COMMON = {
  CONTACT: prefixAPI('callback/create/'),
  LOCATION: prefixAPI('school/choose/{id}/'),
  LIKE: prefixAPI('like/{id}/'),
}

export const ADDITIONAL = {
  CITIES: prefixAPI('city/list/'),
  SCHOOLS: prefixAPI('school/list/'),
  AGE_GROUPS: prefixAPI('age-group/list/'),
  DIRECTIONS: prefixAPI('direction/list/'),
  DIRECTION_TYPES: prefixAPI('direction-type/list/'),
  DIRECTION_TYPES_BY_LABEL: prefixAPI('direction-type/list-by-label/'),
  SCHOOLS_CITIES: prefixAPI('school/list-all/'),
  SCHOOLS_BY_USER: prefixAPI('school/list-manager/'),
  SLOT_LIST: prefixAPI('slot/list/'),
  TEST_DAYS: prefixAPI('slot/list-day/'),
  COURCES_BY_SCHOOL: prefixAPI('course/list-by-school/'),
  GROUPS_BY_COURSE: prefixAPI('group/list-by-course/'),
  GROUPS_BY_SCHOOL: prefixAPI('group/list-by-school/'),
  TEACHERS_BY_GROUP: prefixAPI('teacher/list-by-group/'),
}

export const TEST = {
  TEST_REQUEST: prefixAPI('order-test/create/'),
}

export const GROUPS = {
  CATALOG_CARDS: baseAPI('/ajax-groups/{;filters*}/page/{page}/'),
  CATALOG_CARDS_ONLINE: baseAPI('/ajax-groups/online/{;filters*}/page/{page}/'),
  FILTERS: prefixAPI('filters-groups/'),
  CREATE_REQUEST: prefixAPI('request-group/create/'),
  LIST_BY_STUDENT: prefixAPI('group/list/student/'),
  LIST_FOR_CREATE_TEST: prefixAPI('group/list/for-create-test/'),
  RATING: prefixAPI('group/rating/{id}/'),
  CALENDAR: prefixAPI('group/calendar/{id}/'),
}

export const BOOKS = {
  BOOKS_OF_USER_COURSES: prefixAPI('books/of-user-courses/'),
  BOOKS_RECOMMENDED_OF_USER_COURSES: prefixAPI('books/recommended-of-user-courses/'),
  BOOKS_RECOMMENDED: prefixAPI('books/recommended/'),
}

export const REVIEWS = {
  CATALOG: baseAPI('/ajax-reviews-search/{;filters*}/page/{page}/'),
  FILTERS: prefixAPI('review/filters/'),
  CREATE: prefixAPI('review/create/'),
}

export const VIDEO_REVIEWS = {
  CATALOG: baseAPI('/ajax-video-reviews/page/{page}/'),
}

export const AUTH = {
  PASSWORD_SET: prefixAPI('auth/password/set/'),
  PASSWORD_CHANGE: prefixAPI('auth/password/change/'),
  PASSWORD_RESET: prefixAPI('auth/password/request-reset/'),
  EMAIL_CHANGE: prefixAPI('auth/change-email/'),
  LOGIN: prefixAPI('auth/login/'),
  LOGOUT: prefixAPI('auth/logout/'),
}

export const ORDER = {
  LIST: prefixAPI('order/list/'),
  CREATE: prefixAPI('order/create/'),
  LIST_MANAGER: prefixAPI('order/list-manager/{;filters*}{?limit,offset}'),
  RETRIEVE: prefixAPI('order/retrieve/{id}/'),
  UPDATE: prefixAPI('order/update/{id}/'),
  FILTERS: prefixAPI('order/list-manager-filters/'),
  STATUS_LIST: prefixAPI('order/status-list/'),
  CHANGE_ACTIVATION: prefixAPI('order/turn-activate/{id}/'),
}

export const DOCUMENT = {
  LIST: prefixAPI('document/list-all/'),
}

export const PAYMENT_PERIOD = {
  PAY: prefixAPI('period/pay/'),
}

export const TRANSACTION = {
  CREATE: prefixAPI('transaction/create/'),
  STATUS_LIST: prefixAPI('transaction/status-list/'),
  UPDATE: prefixAPI('transaction/update-manager/{id}/'),
}

export const HOMEWORK = {
  RETRIEVE: prefixAPI('home-task/retrieve/teacher/{id}/'),
  CREATE: prefixAPI('home-task/create/teacher/'),
  UPDATE: prefixAPI('home-task/update/teacher/{id}/'),

  RETRIEVE_MANAGER: prefixAPI('home-task/retrieve/manager/{id}/'),
  CREATE_MANAGER: prefixAPI('home-task/create/manager/'),
  UPDATE_MANAGER: prefixAPI('home-task/update/manager/{id}/'),

  UPDATE_BE_PRESENT_STATUS: prefixAPI('home-task/be-present-set/'),
  LIST_STUDENT: prefixAPI('home-task/list/student/{label}/{;filters*}{?limit,offset}'),
  LIST_TEACHER: prefixAPI('home-task/list/teacher/{;filters*}{?limit,offset}'),
  LIST_FILTER_TEACHER: prefixAPI('home-task/list/teacher-filters/'),
  LIST_MANAGER: prefixAPI('home-task/list/manager/{;filters*}{?limit,offset}'),
  LIST_FILTER_MANAGER: prefixAPI('home-task/list/manager-filters/'),
  BEHAVIOR_SET: prefixAPI('home-task/be-behavior-set/'),
  BEHAVIOR_RETRIEVE: prefixAPI('home-task/be-behavior-retrieve/{id}/'),
  COUNTER_STUDENT: prefixAPI('home-task/counter/{id}/'),
  RETRIEVE_STUDENT: prefixAPI('home-task/retrieve/student/{id}/'),
  NOT_PASSED_LIST: prefixAPI('home-task/no-passed/{groupId}/'),
}

export const LESSON = {
  CATALOG: prefixAPI('lesson/list/{label}/{;filters*}{?limit,offset}'),
  RETRIEVE_STUDENT: prefixAPI('lesson/retrieve/student/{id}/'),
  RETRIEVE_SHORT: prefixAPI('lesson/retrieve/short/teacher/{id}/'),
  RETRIEVE_SHORT_MANAGER: prefixAPI('lesson/retrieve/short/manager/{id}/'),

  CATALOG_TEACHER: prefixAPI('lesson/list/teach/{;filters*}{?limit,offset}'),
  CATALOG_TEACHER_FILTER: prefixAPI('lesson/list/teach-filters/'),
  RETRIEVE_TEACHER: prefixAPI('lesson/retrieve/teacher/{id}/'),
  CREATE: prefixAPI('lesson/create/teacher/'),
  UPDATE: prefixAPI('lesson/update/teacher/{id}/'),

  CATALOG_MANAGER: prefixAPI('lesson/list/manager/{;filters*}{?limit,offset}'),
  CATALOG_MANAGER_FILTER: prefixAPI('lesson/list/manager-filters/'),
  RETRIEVE_MANAGER: prefixAPI('lesson/retrieve/manager/{id}/'),
  CREATE_MANAGER: prefixAPI('lesson/create/manager/'),
  UPDATE_MANAGER: prefixAPI('lesson/update/manager/{id}/'),

  DAY_LIST: prefixAPI('lesson/list/calendar/points/month={month}/'),
  TIME_LIST: prefixAPI('lesson/list/calendar/time/day={day}/'),
  COMMENT_CREATE: prefixAPI('lesson/comment/create/'),
  COMMENT_LIST: prefixAPI('lesson/comment/list/{id}/'),
  COMMENT_DELETE: prefixAPI('lesson/comment/delete/{id}/'),
  LIST_STUDENT: prefixAPI('lesson/list/student/{label}/{;filters*}{?limit,offset}'),

  TEACHER_LIST: prefixAPI('lesson/teachers/{id}/'),
  TEACHER_DEACTIVATE: prefixAPI('lesson/deactivate/{id}/'),
}

export const PASS_HOMEWORK = {
  RETRIEVE: prefixAPI('pass-home-task/retrieve/teacher/{id}/'),
  UPDATE_TEACHER: prefixAPI('pass-home-task/update/teacher/{id}/'),
  UPDATE_MANAGER: prefixAPI('pass-home-task/update/manager/{id}/'),
  PASS_TEACHER: prefixAPI('pass-home-task/create/teacher/'),
  PASS_MANAGER: prefixAPI('pass-home-task/create/manager/'),
  PASS_STUDENT: prefixAPI('pass-home-task/create/student/'),
}

export const STREAM = {
  LIST: prefixAPI('stream/list/{;filters*}{?limit,offset}'),
}

export const DASHBOARD = {
  TEACHER_LIST: prefixAPI('group/list/teacher/dashboard/{;filters*}{?limit,offset}'),
  COMMON_LIST: prefixAPI('group/list/student/dashboard/{;filters*}{?limit,offset}'),
}

export const MESSAGE = {
  LIST: prefixAPI('message/{label}/list/{;filters*}{?limit,offset}'),
  CREATE: prefixAPI('message/create/'),
  TURN_LIKE: prefixAPI('message/turn-like/{id}/'),
  DELETE: prefixAPI('message/delete/{id}/'),
  UPDATE: prefixAPI('message/update/{id}/'),
  PIN_LIST: prefixAPI('message/pinned/{id}/list/'),
  PIN_TURN: prefixAPI('message/turn-pin/{id}/'),
}

export const COMMENT = {
  LIST: prefixAPI('comment/{id}/list/{?limit,offset}'),
  CREATE: prefixAPI('comment/create/'),
  UPDATE: prefixAPI('comment/update/{id}/'),
  DELETE: prefixAPI('comment/delete/{id}/'),
}

export const FAQ = {
  LIST: prefixAPI('faq/list-title/'),
  RETRIEVE: prefixAPI('faq/retrieve/{id}/'),
}

export const BLOG = {
  CATALOG: baseAPI('/article-ajax/list/{label}/page/{page}/'),
  CATEGORIES: prefixAPI('category-blog/list/'),
  SUBSCRIBE: prefixAPI('subscribe/create/'),
}

export const NOTIFICATION = {
  RETRIEVE: prefixAPI('notification/configuries/retrieve/'),
  UPDATE: prefixAPI('notification/configuries/update/'),
  LIST: prefixAPI('notification/message/list/{;filters*}{?limit,offset}'),
  READ_MESSAGE: prefixAPI('notification/message/update/'),
  MESSAGE_COUNTER: prefixAPI('notification/message/counter/'),
}

export const LIKE = {
  ARTICLE: prefixAPI('like-blog/turn/'),
  EVENT: prefixAPI('like-event/turn/'),
}

export const TESTS_MODULE = {
  CREATE_CONFIG: prefixAPI('tests-module/configurations-of-test/create/'),
  UPDATE_CONFIG: prefixAPI('tests-module/configurations-of-test/update/{id}/'),
  DELETE_CONFIG: prefixAPI('test-modules/configurations-of-test/{id}/delete/'),
  VALIDATE_CONFIG: prefixAPI('test-modules/configurations-of-test/{id}/validate/'),
  GET_BASE_CONFIG: prefixAPI('test-modules/configurations-of-test/base-info/{id}/'),
  LIST_OF_CONFIG: prefixAPI('test-modules/configurations-of-test/list/'),
  LIST_OF_CONFIG_SHORT: prefixAPI('test-modules/configurations-short/list/'),
  LIST_OF_CONF_SKILLS: prefixAPI('test-modules/configurations-of-skill/{id}/list/'),
  SKIILS_OF_CONF_UPDATE: prefixAPI('test-modules/skills-of-conf/{id}/update/'),
  GRADES_CONF_RETRIVE: prefixAPI('test-modules/grades-conf/{id}/retrieve/'),
  GRADES_CONF_UPDATE: prefixAPI('test-modules/grades-conf/{id}/update/'),
  LIST_OF_CONF_SKILLS_FOR_GRADE: prefixAPI('test-modules/configurations-of-skill-for-grade/{id}/list/'),

  CREATE_TEST: prefixAPI('test-modules/test/create/'),
  UPDATE_TEST: prefixAPI('test-modules/test/{id}/update/'),
  GET_TEST: prefixAPI('test-modules/test/{id}/retrieve/'),
  GET_TEST_STATUS: prefixAPI('test-modules/test/{id}/retrieve/status/'),
  TEST_LIST: prefixAPI('test-modules/test/list/{;filters*}{?limit,offset}'),
  TEST_LIST_FILTERS: prefixAPI('test-modules/test/list-filter/'),
  LIST_OF_STUDENT_GRADES: prefixAPI('test-modules/test/{id}/student-grades/list/'),
  UPDATE_GRADE_SKILL_OF_STUDENT: prefixAPI('test-modules/test/student-skill-grade/{id}/update/'),
  UPDATE_GRADE_TEST_OF_STUDENT: prefixAPI('test-modules/test/student-test-grade/{id}/update/'),
  UPDATE_TEST_STATUS: prefixAPI('test-modules/test/student-test/{id}/update/'),
  UPDATE_TEST_SHOW_RESULTS: prefixAPI('test-modules/test/student-test/{id}/show-results/update'),
  TYPES_OF_TEST: prefixAPI('test-modules/test/types-of-test/list/'),
  GET_TESTS_STATISTICS_XLSX: prefixAPI('test-modules/statistics/xlsxfile/'),
  HARD_REBUILD_STUDENT_TEST: prefixAPI('test-modules/test/hard-rebuild/{id}/'),
  ACTUALIZE_GROUP_FOR_STUDENT_TEST: prefixAPI('test-modules/test/actualize-group/{id}/'),

  RESULTS_OF_STUDENT_TESTS: prefixAPI('test-modules/test/results-of-student/list/{;filters*}{?limit,offset}'),
}
