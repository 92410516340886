<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{_("Изменить Email")}}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm
      control-input-common(
        rules="required|min:8"
        ref='password'
        type="password"
        v-model="formdata.password"
        class-name="form__input form__input--space_right form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Введите пароль')"
        :required="true"
        name="password"
      )
    +b.form.ma--bottom_sm
      control-input-common(
        rules="custom_email|required"
        type="email"
        class-name="form__input form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Введите новый email адрес')"
        v-model="formdata.newEmail"
        :required="true"
        name="newEmail"
      )
    row.ma--bottom_3sm(justify="end")
      cell
        modal-handler(
          component-name="password-reset-modal"
          modal-class="modal-common modal--size_sm"
        )
          template(#default="{ handler }")
            +b.ls-link.--color_accent-1.--size_3xs(
              @click="$emit('close');handler()"
            ) {{_('Восстановить пароль')}}
    bem-button.de-text--size_2xs(
      width="full"
      block="ls-button"
      appearance="yellow"
      :disabled="isLoad"
      @event="validateBeforeSubmit()"
    ) {{_('Изменить Email')}}
</template>

<script>
import { emailChangeResource } from '@/js/services/auth.service'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  props: {
    objectItem: {},
  },

  mixins: [FormMixin],

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    async send(data) {
      const copyData = await this.prepareData({ ...data })
      return emailChangeResource.execute({}, copyData).then(res => {
        if (this.objectItem.setNewEmail) {
          this.objectItem.setNewEmail(this.formdata.newEmail)
        }
        this.handleResponse({ res, modal: true })
      })
    },
  },
}
</script>
