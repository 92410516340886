<template lang='pug'>
  include ../mixins
  +b.i-catalog
    +e.sidebar
      catalog-sidebar(
        :filters="filters"
        @update:filters="changeFilters"
        :pagination="pagination",
        :attributesResource='attributesResource',
        :additionalResource="additionalResource"
        :label='label'
        :totalItems="totalItems"
        :filterName="filterName"
      )
    +e.content
      ui-preloader(
        :class="{'is-active': loading}"
        loader-color='loader-wrapper__bg--accent-1'
      )
      +b.ds-panel.--space_lg
        +e.element.--offset_bottom
          +b.i-panel
            +b.g-row.--appearance_spaced.--justify_between
              +b.g-cell.g-cols.--5-xl.--6-hd
                +b.P.ds-caption.--size_xs.--size_sm-md.--bold {{ _("Всего товаров:") }}
                  |&nbsp;
                  +b.SPAN.ds-caption.--size_xs.--size_sm-md.--regular {{ totalItems }} {{ _("шт") }}
              +b.g-cell.g-cols.--7-xl.--6-hd
                +b.g-row.--appearance_spaced.--justify_between
                  +b.g-cell.g-cols
                    +b.g-row.--appearance_spaced.--space_xl.--align_center
                      +b.g-cell
                        +b.P.ds-caption.--size_xs.--size_sm-md.--bold {{ _("Сортировать по:") }}
                      +b.g-cell
                        sorting-widget(
                          :value="filters.filters.ordering || 'default'",
                          :filters="filters"
                          @input="changeFilters"
                        )
      +b.g-row.--appearance_spaced.--space_lg(v-if="result && result.items.length")
        +b.g-cell.g-cols.--12-xs(
          v-for="product in result.items"
        )
          //- product-card
      +b.i-panel(v-else)
        +b.ds-caption.--size_md.--size_lg-md.--variant_3 {{ _('Список товаров пуст') }}
      ui-limit-pagination(
        :pagination='pagination'
        @input='handlePaginate'
      )
</template>

<script>
import SortingWidget from '@app/Filters/Sorting'
import CatalogSidebar from './Sidebar'

export default {
  components: {
    CatalogSidebar,
    SortingWidget,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
  ],

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.receive(this.parameters)
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
