<template lang="pug">
  include ../mixins
  +b.g-row
    multiselect.lang-multiselect(
      :class="itemClass"
      :options="languages"
      :hide-selected="true"
      v-model="language"
      track-by="value"
      label="label"
      name="lang"
      :allow-empty="false"
      @input="changeLanguage"
    )
</template>

<script>
import { DEFAULT_LANGUAGE, removeLanguage, generateHref } from '@/js/helpers/url'
import { restoreObject } from '@/js/helpers/utils'

export default {
  name: 'language-control',

  props: {
    itemClass: String,
  },

  data() {
    return {
      currentLang: window.language,
      language: {},
      languages: [
        { value: 'uk', label: this._('UA') },
        { value: 'en', label: this._('EN') },
        { value: 'ru', label: this._('RU') },
      ],
      polishSiteUrl: window.polishSiteUrl,
    }
  },

  mounted() {
    this.compareLangPrefixes()
    this.setPolishLink()
  },

  methods: {
    setPolishLink() {
      if (this.polishSiteUrl) {
        this.languages.push({ value: 'pl', label: 'PL' })
      }
    },

    /*
      parse language code from url
      to show it in the control
    */
    compareLangPrefixes() {
      const path = window.location.pathname
      const [, lang] = path.split('/')
      this.currentLang = 2 === lang.length ? lang : DEFAULT_LANGUAGE
      this.language = restoreObject(this.languages, 'value', this.currentLang)
    },

    changeLanguage() {
      const selectedLang = this.language.value

      if ('pl' === selectedLang) {
        window.location = this.polishSiteUrl

        return
      }

      const urlObj = {
        origin: window.location.origin,
      }
      const location = window.location.pathname
      if (selectedLang !== DEFAULT_LANGUAGE) urlObj.lang = selectedLang
      else urlObj.lang = ''
      urlObj.path = removeLanguage(location)
      this.redirect(generateHref(urlObj))
    },

    redirect(url) {
      window.location.replace(decodeURIComponent(url))
    },
  },
}
</script>
