<template lang="pug">
  include ../../mixins
  div
    range-slider(
      class="vue-slider--variant_default"
      v-model="internal"
      :clickable="false"
      :drag-on-click="true"
      transition="0.2"
      :dot-size="10"
      :height="3"
      :min="min"
      :max="max"
      :interval="interval"
      @drag-end="update"
    )
    +b.g-row.--appearance_spaced.--justify_between.--align_center
      +b.g-cell.g-cols
        +b.P.ds-caption.--size_xs.--color_grey {{ internal[0]|prettifyPrice }} {{ filter.unit || '' }}
      +b.g-cell.g-cols
        +b.P.ds-caption.--size_xs.--color_grey {{ internal[1]|prettifyPrice }} {{ filter.unit || '' }}
</template>

<script>
/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === b ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

export default {
  name: 'filter-slider-widget',
  props: ['value', 'filter'],

  data() {
    return {
      internal: [0, 1],
      interval: 1,
      min: 0,
      max: 1,
    }
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },

  methods: {
    clearMinMax() {
      const [minValue, maxValue] = this.filter.values
      this.min = parseFloat(Math.max(minValue || 0).toFixed(2))
      this.max = parseFloat(Math.min(maxValue || 0).toFixed(2))
      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)
    },

    update() {
      const value = this.clear(this.internal)
      this.input(value)
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      let [min, max] = value
      const [minVal, maxVal] = this.normalize([min, max])
      min = minVal
      max = maxVal
      if (min === this.min && max === this.max) {
        return []
      }

      return [min, max]
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const [min, max] = value

      return [min || this.min, max || this.max]
    },
  },
};

</script>
