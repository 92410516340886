import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')
const Create = () => import('./views/Create')

const ROUTE_NAME = 'profile:orders:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')
const CREATE = p('create')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([
          'manager',
        ], options),
      },
    }),
    route('create/', Create, CREATE, {
      meta: {
        accessConfig: accessPermission([
          'manager',
        ], options),
      },
    }),
    route(':id/detail/', Display, DISPLAY, {
      meta: {
        accessConfig: accessPermission([
          'manager',
        ], options),
      },
      props: true,
    }),
  ]
}
