import Vue from 'vue'
import './case'
import './accordion'
import clamp from './clamp'
import highlightLinks from './highlightLinks'
import youtubeTimeline from './youtubeTimeline'
import horizontalScroll from './horizontalScroll'
import blogHeading from './blogHeading'

Vue.directive('clamp', clamp)
Vue.directive('highlight-on-scroll', highlightLinks)
Vue.directive('youtube-timeline', youtubeTimeline)
Vue.directive('horizontal-scroll', horizontalScroll)
Vue.directive('blog-heading', blogHeading)
