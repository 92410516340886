import {
  createResource,
  postResource,
  patchResource,
} from '@/js/resource/resource'

import { TRANSACTION } from './paths'

export const transactionCreateResource = createResource(
  TRANSACTION.CREATE, postResource
)

export const transactionStatusListResource = createResource(
  TRANSACTION.STATUS_LIST
)

export const transactionUpdateResource = createResource(
  TRANSACTION.UPDATE, patchResource
)
