<template lang='pug'>
include ../mixins
div
  slot(name="content")
  div(
    v-if="hasMore"
    v-observe-visibility="{ callback: handleScroll, intersection: { rootMargin: '200px' } }"
  )
</template>

<script>
export default {
  props: {
    pagination: {},
    dataReceived: {},
  },

  data() {
    return {
      isMounted: true,
    }
  },

  computed: {
    hasMore() {
      return this.pagination.offset + this.pagination.limit < this.pagination.total
    },
  },

  methods: {
    handleScroll(isVisible) {
      if (!isVisible || !this.dataReceived) {
        return
      }
      const init = 0
      const { limit = init } = this.pagination
      const offset = this.pagination.offset + this.pagination.limit
      this.$emit('update:pagination', { limit, offset })
    },
  },
}
</script>
