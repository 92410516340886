<template lang='pug'>
include ../mixins
div
  slot(name="content")
  ui-limit-pagination(
    :need-add-btns="needAddBtns"
    :pagination='pagination'
    @input='handlePaginate'
  )
</template>

<script>
export default {
  props: ['loading', 'pagination', 'needAddBtns', 'items'],
  methods: {
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.$emit('update:pagination', { limit, offset })
    },
  },
}

</script>
