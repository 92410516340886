export function anchors() {
  const links = document.querySelectorAll('.js-anchor-link')
  links.forEach(link => {
    link.onclick = e => {
      e.preventDefault()
      const linkId = link.getAttribute('data-id')
      const item = document.getElementById(linkId)
      item.scrollIntoView()
    }
  })
}
