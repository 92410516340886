import { accessGuard } from '@md/permissions'
import { prefixRoutes } from '@md/routing'
import { prefixLanguage } from '@utils/urls'
import { createRoutes as createProfileRoutes } from '@app/Profile/router'
import { scrollUnlock } from '@/js/helpers/scroll'

export function createRoutes(options) {
  return [
    prefixRoutes(prefixLanguage('/profile/'), createProfileRoutes(options)),
  ]
}

export function scrollBehavior() {
  return new Promise(resolve => {
    setTimeout(() => {
      const app = document.getElementById('app')

      resolve(app.scrollIntoView({ behavior: 'smooth' }))
    }, 200)
  })
}

export function createRouter(options = {}) {
  const { Router, Vue } = options
  const router = new Router({
    mode: 'history',
    base: '/',
    // scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  })

  router.beforeEach(accessGuard(options))
  router.afterEach(() => {
    scrollUnlock()
  })

  return router
}
