<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--top_3xs-xxl.ma--bottom_3sm.ma--right_sm(
      tag="p"
      color="white"
      size="md"
      weight="semi-bold"
    ) {{_("Хочу стать преподавателем")}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
      @keyup.enter="validateBeforeSubmit"
    )
      form-generator(
        :formdata="formdata"
        :form-model="formModel"
        :options="options"
      )
      row.ma--bottom_sm(space="sm" align="center")
        cell(cols="12 6-sm")
          bem-text.pos--relative.ma--bottom_sm-till-sm(
            tag="p"
            block="ls-button"
            appearance="white"
            width="full"
            space="custom-sm"
          ) {{_('Загрузить резюме')}}
            ui-upload(
              type="text"
              :multiple="true"
              @upload="handleUpload($event)"
              @error="resumeError = $event"
            )
            +b.control-hint.--variant_error.P(v-if="resumeError") {{ resumeError }}
        cell(cols="12 6-sm")
          bem-text(
            tag="p"
            color="white"
            size="3xs"
            height="xs"
          ) {{_('Возможно прикрепить файл pdf, doc, docx, размером не больше 5 Mb')}}
      row.ma--bottom_sm(v-if="formdata.resume.length" space="sm" align="center")
        cell
          row(align="center")
            cell
              bem-icon(
                size="2sm"
                type="attach"
                color="white"
              )
            bem-text(
              v-if="formdata.resume.length"
              tag="p"
              color='accent-3'
              weight="semi-bold"
              size="3xs"
            ) {{ normalizeName(formdata.resume) }}
        cell
          bem-button(
            block="ls-link"
            color="white"
            @event="clearResume"
          )
            bem-icon(
              size="2xs"
              type="cross"
            )
      row(space="sm-xl")
        cell(cols="12 6-sm")
          privacy-rules.ma.ma--bottom_sm-till-xl(
            :rules-terms="objectItem.rules"
            :privacy="objectItem.privacy"
          )
        cell(cols="12 6-sm")
          bem-button(
            block="ls-button"
            width="full"
            appearance="yellow-arrow"
            :disabled="isLoad"
            @event="validateBeforeSubmit()"
          ) {{_('відіслати')}}
</template>

<script>
import { becomeTeacherResource } from '@/js/services/teacher.service.js'
import {
  citiesResource,
  schoolsResource,
} from '@/js/services/additional.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  name: 'teacher-form-modal',
  mixins: [FormMixin],
  components: {
    'form-generator': () => import('@/js/components/Forms/FormGenerator'),
    'privacy-rules': () => import('@/js/components/Forms/PrivacyRules'),
  },
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        firstName: '',
        lastname: '',
        email: '',
        phone: '',
        city: '',
        school: '',
        resume: '',
      },
      formModel: [
        {
          cols: '12 6-sm',
          component: 'control-input-pure',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          placeholder: this._('Вашe имя *'),
          model: 'firstName',
          required: true,
          name: 'firstName',
        },
        {
          cols: '12 6-sm',
          component: 'control-input-pure',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          placeholder: this._('Вашe фамилия *'),
          model: 'lastName',
          required: true,
          name: 'lastName',
        },
        {
          cols: '12 6-sm',
          component: 'control-tel-input-pure',
          view: 'input',
          rules: 'required|min:10',
          type: 'number',
          className: 'form__input form__input--white',
          placeholder: this._('Номер телефона*'),
          required: true,
          name: 'phone',
          model: 'phone',
        },
        {
          cols: '12 6-sm',
          component: 'control-input-pure',
          view: 'input',
          rules: 'email',
          type: 'email',
          className: 'form__input form__input--white',
          placeholder: this._('Email'),
          model: 'email',
          required: true,
          name: 'email',
        },
        {
          component: 'control-multiselect-pure',
          cols: '12 6-sm',
          view: 'multiselect',
          rules: 'required',
          className: 'common-multiselect common-multiselect--white',
          placeholder: this._('Выберите город*'),
          model: 'city',
          required: true,
          options: 'citiesList',
          trackBy: 'id',
          label: 'title',
          name: 'city',
          allowEmpty: false,
        },
        {
          component: 'control-multiselect-pure',
          cols: '12 6-sm',
          view: 'multiselect',
          rules: 'required',
          className: 'common-multiselect common-multiselect--white',
          placeholder: this._('Школа*'),
          model: 'school',
          required: true,
          options: 'schoolsList',
          trackBy: 'id',
          label: 'title',
          name: 'school',
          allowEmpty: false,
          tooltip: this._('Сначала выберите город'),
        },
      ],
      isLoad: false,
      options: {
        citiesList: [],
        schoolsList: [],
      },
      resumeError: '',
    }
  },
  async mounted() {
    await this.getCities()
  },
  watch: {
    'formdata.city': {
      handler(nval) {
        this.formdata.school = ''
        this.getSchools(nval.id)
      },
    },
  },
  computed: {
    normalizeName() {
      return function (src) {
        const result = src.split(';')[0]
        return result
      }
    },
  },
  methods: {
    async send(data) {
      this.isLoad = true
      const copyData = await this.prepareData({ ...data })
      if (this.objectItem.vacancyId) {
        copyData.vacancy = this.objectItem.vacancyId
      }
      return becomeTeacherResource.execute({}, copyData)
        .then(res => {
          this.handleResponse({ res, modal: true })
          this.isLoad = false
        })
    },
    getCities() {
      return citiesResource.execute()
        .then(({ data: { item: { items } } }) => {
          this.options.citiesList = items
        })
    },
    getSchools(id) {
      const idCity = id
      return schoolsResource.execute({}, { idCity })
        .then(({ data: { item: { items } } }) => {
          this.options.schoolsList = items
        })
    },
    handleUpload(data) {
      this.formdata.resume = data
    },
    clearResume() {
      this.formdata.resume = ''
    },
  },
}
</script>
