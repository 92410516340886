<template lang="pug">
  include ../mixins
  div
    +b.search-block
      +e.controls
        +e.INPUT.search-input(
          :placeholder="inputPlaceholder"
          v-model='searchString'
          @keyup.enter="goSearchString"
        )
        +e.search-btn
          bem-button(
            block="ls-button"
            appearance="yellow-arrow"
            width="full"
            @event="goSearchString"
            :disabled="searchString.length === 0"
          ) {{_('Поиск')}}
    +b.ma--vertical_2md
      slot(v-if="!isHideSlot")
      renderer(
        :result="results"
        class-render="g-row g-row--space_sm-sm"
      )
    intersect(
      @enter="getAdditionData"
    )
      div
    +b.spinner--sm.--accent-1(v-if="isLoad")
</template>

<script>
import Intersect from 'vue-intersect'
import { getTeacherResource } from '@/js/services/teacher.service.js'

export default {
  name: 'teachers-catalog',
  components: { Intersect },
  props: {
    catalogPagination: Object,
  },
  data() {
    return {
      isLoad: false,
      isHideSlot: false,
      page: 1,
      pagination: this.catalogPagination,
      results: '',
      searchString: '',
      inputPlaceholder: this._('Введите имя преподавателя...'),
    }
  },
  methods: {
    setPage(page) {
      this.page = page
      this.getData()
    },
    goSearchString() {
      this.page = 1
      this.getData()
    },
    async request() {
      const params = {
        page: this.page,
      }
      if (0 < this.searchString.length) params.search = this.searchString
      else delete params.search
      this.isLoad = true
      return getTeacherResource.execute(params)
    },
    getData() {
      this.isHideSlot = true
      this.page = 1
      this.request().then(({ data, meta }) => {
        this.results = data
        this.pagination = meta
        this.isLoad = false
      })
    },
    getAdditionData() {
      if (!this.pagination.last) {
        ++this.page
        this.request().then(({ data, meta }) => {
          this.results += data
          this.pagination = meta
          this.isLoad = false
        })
      }
    },
  },
}
</script>
