import BaseView from '@app/List/View'
import { withDefaultProps } from '@aspectus/vue-utils'

import RouterParametersController from './RouterParametersController'

const composer = (
  listUrl,
  filtersUrl,
  controllerName = 'ControllerCabinet',
  filterName = 'CabinetFilter',
  contentView = 'CatalogContent'
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
  contentView: () => contentView,
})(BaseView)

const CabinetListView = composer()

export default function plugin(Vue) {
  Vue.component('cabinet-list-view', CabinetListView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
