<template lang="pug">
include ../mixins
validation-observer(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit"
)
  +b.form.ma--bottom_sm
    control-input-common(
      rules="required|min:8"
      ref='password'
      type="password"
      v-model="formdata.password"
      class-name="form__input form__input--space_right form__input--light-2"
      label-class="form__label form__label--accent-1"
      :input-label="_('Пароль')"
      :required="true"
      name="password"
    )
  +b.form.ma--bottom_sm
    control-input-common(
      rules="required|min:8|confirmed:password"
      ref='passwordConfirm'
      type="password"
      v-model="formdata.passwordConfirm"
      class-name="form__input form__input--space_right form__input--light-2"
      label-class="form__label form__label--accent-1"
      :input-label="_('Повторите пароль')"
      :required="true"
      name="passwordConfirm"
    )
    validation-provider(
      v-slot="{errors}"
      name="nonFieldErrors"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    validation-provider(
      v-slot="{errors}"
      name="uid"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ _("Идентификатор пользователя не валидный, попробуйте снова отправить письмо для восстановления пароля.") }}
    validation-provider(
      v-slot="{errors}"
      name="token"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ _("Токен не валидный, попробуйте снова отправить письмо для восстановления пароля.") }}
  bem-button.de-text--size_2xs(
    width="full"
    block="ls-button"
    appearance="yellow"
    :disabled="isLoad"
    @event="validateBeforeSubmit()"
  ) {{_('Сохранить')}}
</template>

<script>
import { passwordSetResource } from '@/js/services/auth.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'
import { REDIRECTS } from '@md/users/permissions'
import { eraseCookie } from '@/js/resource/cookie'

export default {
  mixins: [FormMixin],

  props: {
    uid: {
      type: String,
    },
    token: {
      type: String,
    },
  },

  data() {
    return {
      formdata: {
        idCript: this.uid,
        userToken: this.token,
      },
    }
  },

  methods: {
    async send(data) {
      const copyData = await this.prepareData({ ...data })
      return passwordSetResource.execute({}, copyData).then(res => {
        const { data: { item: { typeUser } } } = res
        eraseCookie('user_group_id')
        eraseCookie('user_stream_id')
        const closeEvent = () => {
          window.location = this.$router.resolve({ name: REDIRECTS[typeUser] }).href
        }
        this.handleResponse({ res, modal: true }, closeEvent)
      })
    },
  },
}
</script>
