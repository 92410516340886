import * as is from 'is_js'

export function restoreObject(arr, key, value) {
  let item
  if (Array.isArray(value)) {
    item = []
    value.forEach(el => {
      arr.forEach(itemEl => {
        if (itemEl.options) {
          itemEl.options.forEach(option => {
            if (option[key] === el) {
              item.push(option)
            }
          })
        } else if (itemEl[key] === el) {
          item.push(itemEl)
        }
      })
    })
  } else {
    item = arr.filter(itemEl => itemEl[key] === value)
  }
  return item
}

export function valueFromSelect(item, key) {
  let res
  if (Array.isArray(item)) {
    res = []
    item.forEach(el => {
      res.push(el[key])
    })
  } else {
    res = item[key]
  }
  return res
}

export function snakelize(key) {
  const separator = '_';
  const split = /(?=[A-Z])/;

  return key.split(split)
    .join(separator)
    .toLowerCase()
    .replace(/-+/img, '_')
    .replace(/_+/img, '_');
}

export function camelize(key) {
  if (is.number(key)) {
    return key
  }
  key = key.replace(/__/, '%')
    .replace(/[-_\s]+(.)?/g, (_, a) => (a ? a.toUpperCase() : ''))
    .replace(/(%)(\S)/g, (_, a, b) => `_${b.toUpperCase()}`)
  return key.substr(0, 1).toLowerCase() + key.substr(1)
}

export function titleize(key) {
  return key.charAt(0).toUpperCase() + key.substr(1);
}

export const prettifyPhone = phone => {
  const digits = phone.replace(/\D/g, '')
  const prittyfied = digits.replace(/(\w{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')
  return prittyfied
}

export const notAfterToday = date => {
  const newDate = new Date().setHours(0, 0, 0, 0)
  const preparedDates = date > new Date(newDate)
  return preparedDates
}
