<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{ _("Плохое поведение ученика") }}
  +b.P.ls-text.--size_sm {{ objectItem.message }}
</template>

<script>
export default {
  props: {
    objectItem: {},
  },
}
</script>
