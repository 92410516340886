<template lang="pug">
include ../../../mixins
vue-selection-controller(
  :options="items || []",
  :multiple="multiExpandable",
  :key-getter="keyGetter",
  :states="states",
  v-model="selected",
  v-slot="controller"
)
  component(
    :is="tableComponent", v-bind="$attrs", v-on="$listeners",
    :items="items", :fields="resultFields", :aggregate="aggregate",
    :sort-fields="sortFields", :change-sort="changeSort",
    :reset-sort="resetSort", :active-sort="activeSort",
  )
    template(v-for="key in slotKeys", v-slot:[key]="scope")
      slot(:name="key", v-bind="scope")

    template(v-slot:cell-element-expander="scope")
      +b.g-row.--appearance_nowrap.--appearance_row-reverse.--appearance_row-xl.--align_center.--justify_end.--justify_between-xl
        slot(
          name="additional-action"
          v-bind="scope"
        )
        +b.g-cell
          slot(
            name="expander", v-bind="scope", :controller="controller",
            :option="getOption(scope, controller)"
          )
            +b.A.ls-link.--color_accent-1.--size_2xs.--size_2xs-v2-xl(@click.prevent="controller.change(multiExpandable || !isSelected(scope, controller) ? scope.item : null)") {{ isSelected(scope, controller) ? _("скрыть") : _("подробнее") }}

    template(v-slot:row-after="scope", v-if="shouldExpand")
      slot(name="row-after", v-bind="scope" v-if="isSelected(scope, controller)",)

</template>

<script>

import { tableField } from './utils';
import Table from './Table';

const EXCLUDED_SLOT_KEYS = {
  expanded: true,
  expander: true,
  'cell-element-expander': true,
  'row-after': true,
};

export default {
  name: 'vue-expandable-table',
  inheritAttrs: false,
  props: {
    initialSelected: Array,
    fields: Array,
    items: Array,
    aggregate: Object,
    multiExpandable: Boolean,
    keyGetter: Function,
    expanderBlock: { default: 'vue-expandable-table-expander' },
    states: Object,
    tableComponent: {
      default: () => Table,
    },
    sortFields: {
      default() {
        return []
      },
    },
    activeSort: {
      default() {
        return {}
      },
    },
    changeSort: {},
    resetSort: {},
  },
  watch: {
    initialSelected: {
      handler() {
        if (this.initialSelected && this.initialSelected.length) {
          this.selected = this.initialSelected || [];
        }
      },
      immediate: false,
    },
  },
  data() {
    return {
      selected: this.initialSelected || [],
    };
  },
  computed: {
    slotKeys() {
      return Object.keys(this.$scopedSlots).filter(x => !EXCLUDED_SLOT_KEYS[x]);
    },
    shouldExpand() {
      return !!(this.$scopedSlots.expanded || this.$scopedSlots['row-after']);
    },
    resultFields() {
      return this.shouldExpand ? this.fields.concat([tableField('element-expander')]) : this.fields;
    },
  },
  methods: {
    getOption(scope, controller) {
      return controller.options[scope.index];
    },
    isSelected(scope, controller) {
      const option = this.getOption(scope, controller);

      return option && option.selected;
    },
  },
};

</script>
