import {
  createResource,
  postResource,
} from '@/js/resource/resource'

import { AUTH } from './paths'

export const passwordSetResource = createResource(
  AUTH.PASSWORD_SET, postResource
)

export const passwordChangeResource = createResource(
  AUTH.PASSWORD_CHANGE, postResource
)

export const passwordResetResource = createResource(
  AUTH.PASSWORD_RESET, postResource
)
export const emailChangeResource = createResource(
  AUTH.EMAIL_CHANGE, postResource
)

export const loginResource = createResource(
  AUTH.LOGIN, postResource
)

export const logoutResource = createResource(
  AUTH.LOGOUT, postResource
)
