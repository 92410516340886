import {
  createResource,
  postResource,
} from '@/js/resource/resource'

import { prefixAPI } from '@/js/resource/api'

const REQUEST_CREATE = prefixAPI('request/create/')
const HISTORY_LIST = prefixAPI('question/list/{id}/')
const QUESTION_HISTORY = prefixAPI('question/history/')
const ANSWER_CREATE = prefixAPI('answer/create/')
const ANSWER_LIST = prefixAPI('answer/list/')
const INTERVIEW_CREATE = prefixAPI('interview/create/')
const NON_INTERVIEW = prefixAPI('non/interview/')
const AGE_LIST = prefixAPI('ages/retrieve/')
const SCHEDULE_LIST = prefixAPI('schedules/')
const SCHOOL_LIST = prefixAPI('test-school/list/')

export const quizRequestCreate = createResource(REQUEST_CREATE, postResource)
export const quizReceiveQuestion = createResource(HISTORY_LIST)
export const quizReceiveSection = createResource(QUESTION_HISTORY, postResource)
export const quizPostAnswerResource = createResource(ANSWER_CREATE, postResource)
export const quizReceiveAnswers = createResource(ANSWER_LIST)
export const quizInterviewResource = createResource(INTERVIEW_CREATE, postResource)
export const quizNonInterviewResource = createResource(NON_INTERVIEW, postResource)
export const ageListResource = createResource(AGE_LIST)
export const scheduleListResource = createResource(SCHEDULE_LIST, postResource)
export const schoolListResource = createResource(SCHOOL_LIST)
