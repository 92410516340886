import BemText from './BemText'
import BemButton from './BemButton'
import BemIcon from './BemIcon'
import BemLink from './BemLink'

export default function install(Vue) {
  Vue.component(BemText.name, BemText)
  Vue.component(BemIcon.name, BemIcon)
  Vue.component(BemButton.name, BemButton)
  Vue.component(BemLink.name, BemLink)
}
