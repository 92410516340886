import {
  createResource,
  getResource,
  patchResource,
  postResource,
} from '@/js/resource/resource'

import { PASS_HOMEWORK } from './paths'

export const passHomeworkRetrieveTeacherResource = createResource(
  PASS_HOMEWORK.RETRIEVE, getResource
)

export const passHomeworkUpdateTeacherResource = createResource(
  PASS_HOMEWORK.UPDATE_TEACHER, patchResource
)

export const homeworkPassTeacherResource = createResource(
  PASS_HOMEWORK.PASS_TEACHER, postResource
)

export const passHomeworkUpdateManagerResource = createResource(
  PASS_HOMEWORK.UPDATE_MANAGER, patchResource
)

export const homeworkPassManagerResource = createResource(
  PASS_HOMEWORK.PASS_MANAGER, postResource
)

export const homeworkPassStudentResource = createResource(
  PASS_HOMEWORK.PASS_STUDENT, postResource
)
