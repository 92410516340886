<template lang="pug">
include ../mixins
div
  +b.i-panel.--variant_1(v-for='filter in preparedFilters')
    ui-dropdown(:initial-active="!filter.isClosed")
      template(#default='{ isActive, toggle }')
        +b.accordion
          +e.item(:class="{'is-active': isActive}")
            +e.heading(@click.prevent="toggle")
              +e.title
                +b.P.ds-caption.--size_sm.--bold {{ filter.name }}
              +e.icon
            +b.ds-panel.--space_lg(v-if="isActive")
              +e.element.--offset_top
                tag(
                  :tag="widgetsMap[filter.filterType]"
                  :value="value[filter.key]"
                  :filter-name="filter.key"
                  :filter="filter"
                  @input="'range' === filter.filterType ? updateMultipleValues(filter.key, $event) : updateValue(filter.key, $event)"
                )
</template>

<script>
export default {
  props: {
    preparedFilters: {
      type: Array,
    },
    updateMultipleValues: {
      type: Function,
    },
    updateValue: {
      type: Function,
    },
    widgetsMap: {
      type: Object,
    },
    value: {},
  },
}
</script>
