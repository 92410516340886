<template lang="pug">
  include ../mixins
  +b.cookie-info(v-if="isVisible")
    //- +b.P.test test
    +b.cookie-info-blur
    +e.main
      +e.content
        slot
          +b.html-content.--size_sm test
      +e.buttons
        +e.button.ls-button--appearance_yellow.--width_full.ma--bottom_sm(
          @click.prevent="allow"
        ) {{ _("Зрозуміло") }}
</template>

<script>
import { getCookie, setCookie } from '@utils/cookies'

export default {
  data() {
    return {
      isVisible: false,
      // isVisible: 'allow' !== getCookie('allow_cookies'),
      // coockiePopupText: window.coockiePopupText,
    }
  },

  methods: {
    allow() {
      const seconds = 60
      const minutes = 60
      const hours = 24
      const days = 30
      const expires = seconds * minutes * hours * days

      this.isVisible = false

      setCookie('allow_cookies', 'allow', `;max-age=${expires}`)
    },
  },
  mounted() {
    this.isVisible = 'allow' !== getCookie('allow_cookies')
    console.log(this.isVisible)
  },
}
</script>
