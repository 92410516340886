import VModal from 'vue-js-modal/dist/index.nocss.js'
import GridPlugin from '@aspectus/vue-grid'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import 'vue2-datepicker/index.css'
import 'vue-tel-input/dist/vue-tel-input.css'
import Tooltip from 'vue-directive-tooltip'
import { VPopover } from 'v-tooltip'
// import VueAnimate from 'vue-animate-scroll'
import * as VueAos from 'vue-aos'
import StarRating from 'vue-star-rating'
import VCalendar from 'v-calendar'
import Viewer from 'v-viewer'
import ClickOutside from 'vue-click-outside'
import VueTelInput from 'vue-tel-input'
import VueSelectionController from '@aspectus/vue-selection-controller'
import VueBem from '@aspectus/vue-bem'
import VueDermis from '@aspectus/vue-dermis'
import Tag from '@aspectus/vue-tag'
import { ObserveVisibility } from 'vue-observe-visibility'
import Notifications from 'vue-notification'
import { install as installFilters } from '@/js/utils/filters'

import UiElements from './UiElements'
import Modals from './Modals'
import Forms from './Forms'
import bem from './bem'
import Controls from './Controls'
import Catalog from './Catalog'
import TestRequest from './TestRequest'
import Auth from './Auth'
import VueTable from './packages/vue-table'
import FiltersElements from './Filters'
import ListElements from './List'
import PaginationElement from './Pagination'
import TakeTestElements from './TakeTest/components'

const VueInputMask = require('vue-inputmask').default

export function components(Vue) {
  Vue.use(UiElements)
  Vue.use(Tooltip)
  Vue.use(VueTelInput, {
    dropdownOptions: {
      disabled: false,
      showDialCodeInList: true,
      showFlags: true,
      showSearchBox: true,
      tabindex: 0,
      width: '250px',
    },
  })
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      clickToClose: true,
      scrollable: true,
      transition: 'fade',
      overlayTransition: 'fade',
    },
  })
  Vue.use(VCalendar)
  Vue.use(Viewer, {
    defaultOptions: {
      navbar: false,
      title: false,
    },
  })

  // Vue.use(VueAnimate)
  Vue.use(VueAos)
  Vue.use(GridPlugin)
  Vue.use(UiElements)
  Vue.use(Modals)
  Vue.use(Forms)
  Vue.use(bem)
  Vue.use(Tag)
  Vue.use(Controls)
  Vue.use(Catalog)
  Vue.use(TestRequest)
  Vue.use(Auth)
  Vue.use(VueTable)
  Vue.use(VueSelectionController)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueDermis)
  Vue.use(FiltersElements)
  Vue.use(ListElements)
  Vue.use(PaginationElement)
  Vue.use(installFilters)
  Vue.use(TakeTestElements)

  Vue.component('star-rating', StarRating)
  Vue.component('v-popover', VPopover)

  Vue.directive('click-outside', ClickOutside)
  Vue.directive('observe-visibility', ObserveVisibility)
  Vue.use(VueInputMask)
  Vue.use(Notifications)
}

export default { components }
