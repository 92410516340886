<template lang="pug">
  include ../mixins
  +b.SPAN.control-hint.--variant_1(:class="classes")
    slot
</template>
<script>
export default {
  name: 'ErrorComponent',
  props: {
    classes: {},
  },
}
</script>
