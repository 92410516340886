import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:group:homework:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')

export function createRoutes(options) {
  return [
    route(':group/homework/list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee',
          'student',
        ], options),
      },
      props: true,
    }),
    route('homework/:id/detail/', Display, DISPLAY, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee',
          'student',
        ], options),
      },
      props: true,
    }),
  ]
}
