<template lang="pug">
  include ../mixins
  div
    div(v-if="!detailIsHidden")
      p(ref="clampField") {{ localText }}
      +b.P.ls-link.--size_2xs-v2.--color_accent-1(
        @click.prevent="displayMore"
      ) {{ _('Показать полностью') }}
    div(v-else)
      p {{ text }}
      +b.P.ls-link.--size_2xs-v2.--color_accent-1(
        v-if="needCollapse"
        @click.prevent="hide"
      ) {{ _('Скрыть') }}
</template>

<script>
import TextModal from '@/js/components/Modals/TextModal'

export default {
  name: 'clamp-field',

  props: {
    text: {},
    title: {},
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      detailIsHidden: false,
      localText: this.text,
      needCollapse: false,
    }
  },

  watch: {
    text: {
      immediate: true,
      handler() {
        this.checkDetailVisibility()
      },
    },
  },

  methods: {
    checkDetailVisibility() {
      const zero = 0
      const maxLength = 230
      this.$nextTick(() => {
        if (this.$refs.clampField) {
          const { innerText } = this.$refs.clampField
          if (innerText.length > maxLength) {
            this.detailIsHidden = false
            this.localText = `${innerText.slice(zero, maxLength)}...`
            this.needCollapse = true
          } else {
            this.detailIsHidden = true
          }
        }
      })
    },

    hide() {
      this.detailIsHidden = false
    },

    openModal() {
      this.$modal.show(TextModal, {
        title: this.title,
        text: this.text,
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_md modal--padding_sm modal--color_white modal--border',
      })
    },

    displayMore() {
      if (this.isCollapse) {
        this.detailIsHidden = true
      } else {
        this.openModal()
      }
    },
  },
}
</script>
