import {
  createResource,
  postResource,
  getResource,
} from '@/js/resource/resource'

import { TEACHER } from './paths'

export const getTeacherResource = createResource(
  TEACHER.GET_TEACHER, getResource
)

export const becomeTeacherResource = createResource(
  TEACHER.BECOME_TEACHER, postResource
)

export const teacherSchoolsResource = createResource(
  TEACHER.SCHOOL_LIST, getResource
)

export const teacherGroupsResource = createResource(
  TEACHER.GROUP_LIST, getResource
)
