import {
  createResource,
  postResource,
  getResource,
} from '@/js/resource/resource'

import { ADDITIONAL } from './paths'

export const directionResource = createResource(
  ADDITIONAL.DIRECTIONS, postResource
)

export const directionTypesResource = createResource(
  ADDITIONAL.DIRECTION_TYPES, postResource
)

export const directionTypesByLabelResource = createResource(
  ADDITIONAL.DIRECTION_TYPES_BY_LABEL, postResource
)

export const schoolsCitiesResource = createResource(
  ADDITIONAL.SCHOOLS_CITIES, postResource
)

export const citiesResource = createResource(
  ADDITIONAL.CITIES, getResource
)

export const ageGroupsResource = createResource(
  ADDITIONAL.AGE_GROUPS, getResource
)

export const schoolsResource = createResource(
  ADDITIONAL.SCHOOLS, postResource
)

export const slotsListResource = createResource(
  ADDITIONAL.SLOT_LIST, postResource
)

export const testDaysResource = createResource(
  ADDITIONAL.TEST_DAYS, postResource
)

export const coursesBySchoolResource = createResource(
  ADDITIONAL.COURCES_BY_SCHOOL, postResource
)

export const groupsByCourseResource = createResource(
  ADDITIONAL.GROUPS_BY_COURSE, postResource
)

export const groupsBySchoolResource = createResource(
  ADDITIONAL.GROUPS_BY_SCHOOL, postResource
)

export const teachersByGroupResource = createResource(
  ADDITIONAL.TEACHERS_BY_GROUP, postResource
)

export const schoolsByUserResource = createResource(
  ADDITIONAL.SCHOOLS_BY_USER, postResource
)
