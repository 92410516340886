/* eslint-disable */
/**
 * Python-like api for class creation.
 *
 * Try not to use `constructor`, use `init` method instead.
 *
 * A little bit easier inheritance, because in an init method you may
 * call super in any order. And it's also shorter to write.
 *
 * @export
 * @class Initable
 */
export class Initable {
  constructor() {
    this.init.apply(this, arguments)
  }

  init() {

  }
}

export function getSuperMethod(self, method) {
  return self.constructor.super.options.methods[method].bind(self)
}

export function getMixinOption(mixin, name) {
  return ('options' in mixin ? mixin.options : mixin)[name]
}

export function getMixinMethod(mixin, method, self = null) {
  const func = getMixinOption(mixin, 'methods')[method]
  return self ? func.bind(self) : func
}
