<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{_("Добавить выполненную домашку")}}
  +b.ma--top_3xs-xxl.--bottom_3sm
    +b.P.ls-text.--color_coral.--size_2xs.--height_md {{_("При сдаче или пересдаче после") }}
      |&nbsp;
      +b.SPAN.ls-text.--weight_semi-bold {{ objectItem.deadline }}
      |&nbsp;
      | {{ _("от максимального балла будет отниматься штраф за просрочку.")}}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.ma.--bottom_sm
      validation-provider(
        rules="required"
        v-slot="{errors}"
        name="content"
        tag="div"
      )
        ui-editor(v-model="formdata.content")
        +b.form.ma.--bottom_sm(v-if="errors[0]")
          +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    +b.g-row.--appearance_spaced.--space_sm
      +b.g-cell.g-cols--12.--6-sm.--4-lg.--narrow-xl
        +b.form.pos.--relative
          +b.BUTTON.ls-button.--variant_accent-1.--width_full.--variant_1 {{_('Добавить материалы')}}
          +b.form.ma.--bottom_sm(v-if="uploadError")
            +b.SPAN.control-hint.--variant_1 {{ uploadError }}
          ui-upload(
            type="custom"
            :formats="['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'zip', 'rar', 'mp4', 'avi', 'mp3']"
            :multiple="true"
            @upload="handleUpload($event)"
            @error="uploadError = $event"
          )
      +b.g-cell.g-cols--12
        +b.g-row.--appearance_spaced(v-if="formdata.files.length")
          +b.g-cell.g-cols(v-for="(item, index) in formdata.files")
            +b.i-file
              +e.icon.--variant_default
                +b.I.ls-icon.--type_file
              +e.SPAN.text {{ item.name }}
              +e.icon.--variant_close(@click.prevent="removeFile(index)")
                +b.I.ls-icon.--type_cross
    validation-provider(
      v-slot="{errors}"
      name="nonFieldErrors"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    validation-provider(
      v-slot="{errors}"
      name="homeTask"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    +b.i-divider
    +b.g-row.--appearance_spaced.--justify_end
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_accent-1-bordered.--width_full(
          @click.prevent="$emit('close')"
        ) {{_('Отмена')}}
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_1(
          @click.prevent="validateBeforeSubmit()"
        ) {{_('Готово')}}
</template>

<script>
import { homeworkPassStudentResource } from '@/js/services/pass-homework.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'
import SuccessModal from '@/js/components/Modals/SuccessModal'

export default {
  props: {
    objectItem: {},
  },

  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        files: [],
        homeTask: this.objectItem.homeTask,
      },
      uploadError: null,
    }
  },

  methods: {
    handleUpload(data) {
      const [name, src] = data.split(';')
      const obj = { name, src, data }
      this.formdata.files.push(obj)
    },
    removeFile(index) {
      const one = 1
      this.formdata.files.splice(index, one)
    },

    prepareDataCustom(obj) {
      const data = JSON.parse(JSON.stringify(obj))
      data.files = data.files.map(el => {
        el.file = el.data
        delete el.data
        delete el.src
        delete el.name
        return el
      })
      return data
    },

    async send(data) {
      const copyData = await this.prepareDataCustom({ ...data })
      return homeworkPassStudentResource.execute({}, copyData).then(() => {
        const timeout = 200

        setTimeout(() => {
          const title = this._('Домашка успешно добавленна')
          this.showModal(title)
        }, timeout)
      })
    },

    showModal(title) {
      this.$modal.show(SuccessModal, {
        title,
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_sm modal--color_white modal--border',
      }, {
        'before-close': () => {
          window.location.reload()
        },
      })
    },
  },
}
</script>
