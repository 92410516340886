import { createBlockNameGenerator } from '@aspectus/bem'

const blockName = createBlockNameGenerator()

export default {
  props: {
    tag: String,
    block: String,
    type: String,
    color: {},
    space: {},
    variant: {
      type: [String, Array],
    },
    width: {
      type: [String, Array],
    },
    height: String,
    appearance: [String, Array],
    border: String,
    size: String,
    text: String,
    href: String,
    disable: Boolean,
    target: String,
    weight: String,
    rounded: String,
  },
  computed: {
    genetatedClass() {
      return blockName(this.block, {
        type: this.type,
        color: this.color,
        space: this.space,
        variant: this.variant,
        width: this.width,
        height: this.height,
        appearance: this.appearance,
        border: this.border,
        size: this.size,
        text: this.text,
        disable: this.disable,
        target: this.target,
        weight: this.weight,
        rounded: this.rounded,
      })
    },
  },
}
