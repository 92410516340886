import VueI18n from 'vue-i18n'
import { components } from '@/js/components'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import ResourceComponents from '@/js/resource'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(components)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(ResourceComponents)
}

export default { install }
