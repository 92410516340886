/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val) {
  const cookie = [name, '=', val, '; path=/;'].join('')
  document.cookie = cookie
}

export function eraseCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
