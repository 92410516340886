import { createStore as authCreateStore } from '@md/auth'

import {
  profileRetrieveResource,
  profileUpdateResource,
} from '@/js/services/profile.service'

export function createStore(parameters) {
  return {
    namespaced: true,

    modules: {
      auth: authCreateStore({
        receiveResource: profileRetrieveResource,
        updateResource: profileUpdateResource,
        ...parameters,
      }),
    },
  }
}
