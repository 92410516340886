import SuccessModal from '@/js/components/Modals/SuccessModal'
import { valueFromSelect } from '../helpers/utils'

const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    if (Array.isArray(errors[x])) {
      acc[x] = errors[x].map(messageGetter)
    } else {
      acc[x] = [errors[x].message]
    }
    return acc
  }, {})
}

export function getCommonParsedErrors(e) {
  return e.json().then(body => {
    const errors = {}

    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
    return errors
  })
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
      nonFieldErrors: [],
    }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }
        if (!this.updateValidator) {
          return e
        }
        return e.json().then(body => {
          const errors = {}
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.updateValidator(errors)
          return e
        })
      })
    },
    async submit(isValid, handler, meta) {
      if (!isValid) {
        return Promise.reject()
      }
      this.isLoad = true
      return this.catchFormErrors(handler(this.formdata, meta))
        .finally(() => {
          this.isLoad = false
        })
    },
    async validateBeforeSubmit(handler = this.send, meta) {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await this.submit(isValid, handler, meta)
      } else {
        this.isLoad = false
        this.scrollToError()
      }
    },
    handleResponse(response, action) {
      const { redirect, modal, noFieldClearing = false } = response
      if (!noFieldClearing) {
        Object.keys(this.formdata).forEach(key => {
          this.formdata[key] = null
        })
      }
      if (modal) {
        const { res: { data: { item: { message: { title, text } } } } } = response
        this.$emit('close')
        this.showModal(
          title,
          text,
          action
        )
      }
      if (redirect) {
        const { res: { data: { item: { location } } } } = response
        window.location.replace(location)
      }
      this.$refs.observer.reset()
      this.nonFieldErrors = []
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.observer.setErrors(parsed)
      this.nonFieldErrors = parsed.nonFieldErrors
    },
    showModal(title, text, action) {
      this.$modal.show(SuccessModal, {
        title,
        text,
      },
      {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_sm modal--color_white modal--border',
      }, {
        'before-close': () => { this.$emit('action') },
        closed: () => { if (action) action() },
      })
    },
    // prefill input fields with data
    fillFormdata(userData) {
      const obj = {}
      Object.keys(userData).forEach(key => {
        if ('phone' === key) obj[key] = userData[key] || '+38'
        else obj[key] = userData[key]
      })
      return obj
    },
    prepareData(obj, objKey = 'id') {
      Object.keys(obj).forEach(key => {
        if ('object' === typeof obj[key] && null !== obj[key]) {
          obj[key] = valueFromSelect(obj[key], objKey)
        }
      })
      return obj
    },
    scrollToError() {
      const errArray = document.getElementsByClassName('control-hint')
      if (errArray.length) {
        errArray[0].scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    },
  },
}
