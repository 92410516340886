<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--top_3xs-xxl.ma--bottom_3sm.ma--right_2sm(
      tag="h1"
      size="md"
      color="white"
      weight="semi-bold"
    ) {{_('Выберите школу')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
      @keyup.enter="validateBeforeSubmit"
    )
      form-generator(
        :formdata="formdata"
        :form-model="formModel"
        :options="options"
      )
      bem-text.ma--top_3xs-xxl.ma--bottom_3sm.ma--right_2sm(
        tag="p"
        size="sm"
        color="white"
        height="xs"
      ) {{_('Для того, чтобы вам отображалась актуальная для Вас информация, выберите, пожалуйста  подходящую для Вас школу')}}
      bem-button(
        width="full"
        block="ls-button"
        appearance="yellow-arrow"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Відіслати')}}
</template>

<script>
import FormMixin from '@/js/mixins/form.mixin.js'
import {
  schoolsCitiesResource,
} from '@/js/services/additional.service.js'
import { locationResource } from '@/js/services/common.service.js'
import { setCookie } from '@/js/resource/cookie'

// const COOKIE_KEY = 'school_has_already_been_changed'

export default {
  name: 'location-modal',
  components: {
    'form-generator': () => import('@/js/components/Forms/FormGenerator'),
  },
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        school: '',
      },
      formModel: [
        {
          cols: '12',
          component: 'control-multiselect-pure',
          view: 'multiselect',
          rules: 'required',
          className: 'common-multiselect common-multiselect--white school-multiselect',
          placeholder: this._('Школа *'),
          model: 'school',
          required: true,
          options: 'schoolsList',
          trackBy: 'id',
          label: 'title',
          name: 'school',
        },
      ],
      options: {
        schoolsList: [],
      },
    }
  },
  mounted() {
    this.getSchools()
  },
  methods: {
    send() {
      const { school } = this.formdata
      return locationResource.execute({ id: school.id }, {})
        .then(() => {
          setCookie('city_id', school.cityId)
          window.location.reload()
          // const cookieExists = getCookie(COOKIE_KEY)
          // if (cookieExists) {
          //   window.location.reload()
          // } else {
          //   setCookie(COOKIE_KEY, true)
          //   window.location = window.schoolTestUrl
          // }
        })
    },
    getSchools() {
      return schoolsCitiesResource.execute({}, {})
        .then(({ data: { item: { items } } }) => {
          this.options.schoolsList = items
        })
    },
  },
}
</script>
