import Components from './components'

function install(Vue) {
  Vue.use(Components)
}

export * from './resource'
export default {
  install,
}
