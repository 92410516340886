import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import UnifiedControlElement from './UnifiedControlElement'
import DatePicker from './DatePicker'
import TelInput from './TelInput'
// import InputAction from './InputAction'
import ErrorComponent from './ErrorComponent'

import StaticLabel from './StaticLabel'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

const Multiselect = () => import('./Multiselect')

const composerForInput = (input, action, label = StaticLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  actionComponent: () => action,
})(UnifiedControlElement)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)

const composerCommon = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)

// input
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)

// input without label
const PureInput = composerForInput('Input', null, null)
const ControlPureInput = composer(PureInput)

// input without label
const CommonInput = composerForInput('Input')
const ControlCommonInput = composerCommon(CommonInput)

// multiselect without label
const MultiselectPure = composerForInput(Multiselect, null, null)
const ControlPureMultiselect = composer(MultiselectPure)

// multiselect
const MultiselectWithLabel = composerForInput(Multiselect, StaticLabel)
const ControlMultiselect = composer(MultiselectWithLabel)

// multiselect
const CommonMultiselect = composerForInput(Multiselect, StaticLabel)
const ControlMultiselectCommon = composerCommon(CommonMultiselect)

// picker with label
const PickerWithStaticLabel = composerForInput(DatePicker, StaticLabel)
const ControlPickerStatic = composer(PickerWithStaticLabel)

// picker with label
const CommonPicker = composerForInput(DatePicker, StaticLabel)
const ControlPickerCommon = composerCommon(CommonPicker)

// TelInput with label
const TelInputWithStaticLabel = composerForInput(TelInput, StaticLabel)
const ControlTelInputStatic = composer(TelInputWithStaticLabel)

// TelInput with label
const CommonTelInput = composerForInput(TelInput, StaticLabel)
const ControlTelInputCommon = composerCommon(CommonTelInput)

// TelInput without label
const PureTelInput = composerForInput(TelInput, null, null)
const ControlPureTelInput = composer(PureTelInput)

// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

// textarea
const CommonTextarea = composerForInput('Textarea')
const ControlTextareaCommon = composerCommon(CommonTextarea)

export default function install(Vue, { prefix = 'Control' } = {}) {
  Vue.component('Multiselect', Multiselect)
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}InputPure`, ControlPureInput)
  Vue.component(`${prefix}InputCommon`, ControlCommonInput)
  Vue.component(`${prefix}MultiselectPure`, ControlPureMultiselect)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}TextareaCommon`, ControlTextareaCommon)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}MultiselectCommon`, ControlMultiselectCommon)
  Vue.component(`${prefix}DatePicker`, ControlPickerStatic)
  Vue.component(`${prefix}DatePickerCommon`, ControlPickerCommon)
  Vue.component(`${prefix}TelInput`, ControlTelInputStatic)
  Vue.component(`${prefix}TelInputCommon`, ControlTelInputCommon)
  Vue.component(`${prefix}TelInputPure`, ControlPureTelInput)
  Vue.component('vue-phone-number-input', VuePhoneNumberInput)
}
