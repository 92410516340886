<template lang='pug'>
  include ../mixins
  +b.i-catalog.--variant_default
    div(:class="isPrimaryStyling ? '' : 'ls-card--space_3sm ls-card--color_white ls-card--border_sm-adaptive'")
      slot(name="heading")
      catalog-sidebar(
        :filters="filters"
        @update:filters="changeFiltersLocally"
        :pagination="pagination",
        :attributesResource='attributesResource',
        :additionalResource="additionalResource"
        :label='label'
        :filterName="filterName"
        :needSearch="needSearch"
        :searchHint="searchHint"
      )
      +e.content
        ui-preloader(
          :class="{'is-active': loading}"
          loader-color='loader-wrapper__bg--accent-1'
        )
        tag(
          :tag="contentView"
          :loading="loading",
          :items="result ? result.items : []",
          :pagination="pagination",
          :needAddBtns="needAddBtns"
          :data-received="dataReceived"
          @update:pagination="changePagination"
        )
          template(#content)
            slot(name="content")
</template>

<script>

import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'
import InfiniteContent from './InfiniteContent'

export default {
  components: {
    CatalogSidebar,
    CatalogContent,
    InfiniteContent,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'label',
    'filterName',
    'needSearch',
    'searchHint',
    'additionalResource',
    'needAddBtns',
    'isPrimaryStyling',
    'contentView',
    'dataReceived',
  ],

  created() {
    this.getData()
  },

  methods: {
    getData(sortParams) {
      if (sortParams && this.parameters.filters.sort) {
        this.parameters.filters.sort = sortParams.sort
      } else {
        this.parameters.filters = { ...this.parameters.filters, ...sortParams }
      }
      if (sortParams && Object.prototype.hasOwnProperty.call(sortParams, 'offset')) {
        this.parameters.offset = sortParams.offset
        delete this.parameters.filters.offset
      }
      this.changeFilters(this.parameters)
      // this.receive(this.parameters)
    },

    changeFiltersLocally(params) {
      const data = { ...params }
      data.filters.sort = this.parameters.filters.sort

      this.changeFilters(data)
    },
  },
}

</script>
