<template lang="pug">
  component(
    is="button"
    :class="genetatedClass"
    :disabled="disable"
    @click.prevent="$emit('event', $event)"
  )
    slot
</template>

<script>
import bemMixin from './bem-mixin'

export default {
  name: 'bem-button',
  mixins: [
    bemMixin,
  ],
  props: {
    block: {
      default: () => 'ls-button',
    },
  },
}
</script>
