<script>
export default {
  name: 'modal-handler',
  props: {
    item: {},
    callback: {},
    question: {
      type: String,
    },
    modalClass: {
      type: String,
      default: 'modal--size_sm',
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
  },
  render() {
    return this.$scopedSlots.default({
      handler: this.toggleModalHandler,
    })
  },
  methods: {
    toggleModalHandler() {
      this.$modal.show(() => import('./Confirm'), {
        question: this.question,
        callback: this.callback,
        item: this.item,
      }, {
        classes: `modal modal--container ${this.modalClass}`,
        name: 'modal',
        clickToClose: this.clickToClose,
      })
    },
  },
}
</script>
