/**
 * @param {string} url
 */
export function getVideoId(url) {
  const regExp = /^.*(shorts\/|youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const eleven = 11

  if (match && match[2].length === eleven) {
    return match[2]
  }

  return 'error'
}
