<template lang="pug">
  include ../mixins
  div
    filters-renderer(
      :widgets-map="$options.widgetsMap"
      :prepared-filters="commonFilters"
      :update-multiple-values="updateMultipleValues"
      :update-value="updateValue"
      :value="value"
    )
</template>

<script>
import UiMixin from './mixins/UiMixin'
import FiltersRenderer from './FiltersRenderer'

export default {
  mixins: [UiMixin],
  components: {
    FiltersRenderer,
  },

  props: [
    'value',
    'filters',
    'pagination',
    'additionalResource',
    'label',
  ],
}
</script>
