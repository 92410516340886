<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initialParams"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :get-data="getData"
    :is-infinite-content="isInfiniteContent"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="listResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      :is-infinite-content="isInfiniteContent"
      v-slot="paginatedProps"
      @result="$emit('result', $event);setResult()"
    )
      component(
        ref="controller"
        :is="controllerName"
        :attributesResource='filtersResource'
        :additionalResource='additionalResource'
        v-bind="paginatedProps",
        :totalItems="(paginatedProps.result && paginatedProps.result.pagination) ? paginatedProps.result.pagination.total : 0"
        :label='label',
        :filterName="filterName"
        :needSearch="needSearch"
        :searchHint="searchHint"
        :needAddBtns="needAddBtns"
        :isPrimaryStyling="isPrimaryStyling"
        :contentView="contentView"
        :dataReceived="dataReceived"
      )
        template(#heading)
          slot(name="heading")
        template(#content)
          slot(
            name="content"
            :items="paginatedProps.result ? paginatedProps.result.items : []"
            :total="paginatedProps.pagination.total"
          )
</template>

<script>
import Controller from '@app/List/Controller'
import ControllerCabinet from '@app/List/ControllerCabinet'
import InfiniteContent from '@app/List/InfiniteContent'
import {
  // friendlyUrlParser,
  parsePaginationUrl,
  queryStringParser,
} from '@app/Filters/transformers'

export default {
  components: {
    Controller,
    ControllerCabinet,
    InfiniteContent,
  },

  props: {
    initial: {},
    baseUrl: {},
    listResource: {},
    filtersResource: {},
    additionalResource: {},
    label: {},
    needSearch: {},
    searchHint: {},
    controllerName: {},
    filterName: {},
    needAddBtns: {},
    isPrimaryStyling: {
      default: false,
    },
    contentView: {},
    searchUrlParse: {},
    isInfiniteContent: {},
  },

  data() {
    return {
      initialParams: {},
      dataReceived: false,
    }
  },

  created() {
    const query = parsePaginationUrl()
    this.initialParams = {
      ...this.initial,
      ...query,
    }
  },

  methods: {
    getData(orderParams) {
      if (this.$refs.controller && this.$refs.controller.getData) {
        this.$refs.controller.getData(orderParams)
      }
    },

    setResult() {
      const timeout = 300
      setTimeout(() => {
        this.dataReceived = true
      }, timeout)
    },

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },

    parametersFrom({ ...rest }) {
      const search = decodeURIComponent(window.location.search)
      const data = queryStringParser(search)
      const query = parsePaginationUrl()
      const pagination = {
        limit: query.limit || this.initial.limit,
        offset: query.offset || this.initial.offset || 0,
      }

      return {
        ...rest,
        filters: data,
        label: this.label,
        ...pagination,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
