import ModalHandler from './ModalHandler'
import LocationModal from './LocationModal'
import VideoModal from './VideoModal'
import HomeworkAdd from './HomeworkAdd'
import ConfirmModalHandler from './ConfirmModalHandler'
import GroupListModal from './GroupList'
import PasswordChangeModal from './PasswordChange'
import PasswordResetModal from './PasswordReset'
import EmailChangeModal from './EmailChange'
import EmailChangeChildModal from './EmailChangeChild'
import LoginModal from './Login'
import TransactionFormModal from './TransactionForm'
import HomeworkPointModal from './HomeworkPoint'
import CommonVideoModal from './CommonVideoModal'
import OrderPaymentModal from './OrderPayment'
import ChildBehaviorModal from './ChildBehavior'
import InfoModal from './InfoModal'
import CookieInfo from './CookieInfo'
import ShowUpdateComment from './ShowUpdateComment'

export default function install(Vue) {
  Vue.component(ModalHandler.name, ModalHandler)
  Vue.component(LocationModal.name, LocationModal)
  Vue.component(VideoModal.name, VideoModal)
  Vue.component('homework-add', HomeworkAdd)
  Vue.component('confirm-modal-handler', ConfirmModalHandler)
  Vue.component('group-list-modal', GroupListModal)
  Vue.component('password-change-modal', PasswordChangeModal)
  Vue.component('password-reset-modal', PasswordResetModal)
  Vue.component('email-change-modal', EmailChangeModal)
  Vue.component('email-change-child-modal', EmailChangeChildModal)
  Vue.component('auth-login-modal', LoginModal)
  Vue.component('transaction-form-modal', TransactionFormModal)
  Vue.component('homework-point-modal', HomeworkPointModal)
  Vue.component('common-video-modal', CommonVideoModal)
  Vue.component('order-payment-modal', OrderPaymentModal)
  Vue.component('child-behavior-modal', ChildBehaviorModal)
  Vue.component('info-modal', InfoModal)
  Vue.component('coockie-info', CookieInfo)
  Vue.component('show-update-comment-modal', ShowUpdateComment)
}
