export default {
  bind(node) {
    document.addEventListener('DOMContentLoaded', () => {
      node.addEventListener('click', () => {
        const time = node.getAttribute('data-time')

        const event = new CustomEvent('seekTo', { detail: { time } })
        document.dispatchEvent(event)
      })
    })
  },
}
