import { mapActions } from 'vuex'
import { createI18n } from '@/i18n'
// import LocationModal from '@/js/components/Modals/LocationModal'
// import { getCookie } from '@/js/resource/cookie'
import { createStoreObject } from './store'
import { createRouter } from './router'

export function createApp({ Vue, I18n, Router, Store }) {
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  const router = createRouter({ Vue, Router, store })

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],

    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },

    mounted() {
      if (window.isAuthenticated) {
        this.initialReceive()
      }
      // const timeout = 5000
      // if (getCookie('school_id') === undefined) {
      //   setTimeout(() => {
      //     this.$modal.show(LocationModal, {}, {
      //       name: 'location-modal',
      //       classes: 'modal-common modal--size_sm modal--color_accent-1 modal--border modal--padding_sm',
      //     })
      //   }, timeout)
      // }
    },

    methods: {
      ...mapActions('users/auth', [
        'initialReceive',
      ]),
    },
  })

  return { app, i18n, router, store }
}
