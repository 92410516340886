import {
  createResource,
  getResource,
  postResource,
} from '@/js/resource/resource'

import { BLOG } from './paths'

export const newsCatalogResource = createResource(
  BLOG.CATALOG, getResource
)

export const newsCategoriesListResource = createResource(
  BLOG.CATEGORIES, getResource
)

export const newsSubscribeResource = createResource(
  BLOG.SUBSCRIBE, postResource
)
