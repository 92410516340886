<script>
import { getVideoId } from '@utils/video'

export default {
  name: 'video-block',
  props: {
    src: String,
    serverPreview: String,
  },
  data() {
    return {
      isOpen: false,
      formatSrc: '',
      preview: '',
    }
  },
  mounted() {
    this.getVideo()
    if ('None' === this.serverPreview || !this.serverPreview) {
      this.preview = this.getVideoPreview(this.src)
    } else {
      this.preview = this.serverPreview
    }
  },
  methods: {
    openVideo() {
      this.isOpen = true
    },
    getVideo() {
      const item = `//www.youtube.com/embed/${getVideoId(this.src)}?autoplay=1`
      this.formatSrc = item
    },
    getVideoPreview(url) {
      const videoPreview = `//img.youtube.com/vi/${getVideoId(url)}/sddefault.jpg`
      return videoPreview
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: this.openVideo,
      isOpen: this.isOpen,
      src: this.formatSrc,
      preview: this.preview,
    })
  },
}
</script>
