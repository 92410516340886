import {
  createResource,
  getResource,
  postResource,
  patchResource,
} from '@/js/resource/resource'

import { HOMEWORK } from './paths'

export const homeworkRetrieveTeacherResource = createResource(
  HOMEWORK.RETRIEVE, getResource
)

export const homeworkCreateTeacherResource = createResource(
  HOMEWORK.CREATE, postResource
)

export const homeworkUpdateTeacherResource = createResource(
  HOMEWORK.UPDATE, patchResource
)

export const homeworkRetrieveManagerResource = createResource(
  HOMEWORK.RETRIEVE_MANAGER, getResource
)

export const homeworkCreateManagerResource = createResource(
  HOMEWORK.CREATE_MANAGER, postResource
)

export const homeworkUpdateManagerResource = createResource(
  HOMEWORK.UPDATE_MANAGER, patchResource
)

export const homeworkUpdateBePresentStatusResource = createResource(
  HOMEWORK.UPDATE_BE_PRESENT_STATUS, postResource
)

export const homeworkListTeacherResource = createResource(
  HOMEWORK.LIST_TEACHER, getResource
)

export const homeworkListFilterTeacherResource = createResource(
  HOMEWORK.LIST_FILTER_TEACHER, getResource
)

export const homeworkListManagerResource = createResource(
  HOMEWORK.LIST_MANAGER, getResource
)

export const homeworkListFilterManagerResource = createResource(
  HOMEWORK.LIST_FILTER_MANAGER, getResource
)

export const behaviorSetResource = createResource(
  HOMEWORK.BEHAVIOR_SET, postResource
)

export const behaviorRetrieveResource = createResource(
  HOMEWORK.BEHAVIOR_RETRIEVE, getResource
)

export const homeworkListStudentResource = createResource(
  HOMEWORK.LIST_STUDENT, getResource
)

export const homeworkListCountStudentResource = createResource(
  HOMEWORK.COUNTER_STUDENT, getResource
)

export const homeworkRetrieveStudentResource = createResource(
  HOMEWORK.RETRIEVE_STUDENT, getResource
)

export const homeworkNotPassedListResource = createResource(
  HOMEWORK.NOT_PASSED_LIST, getResource
)
