import { scrollUnlock, scrollLock } from '@/js/helpers/scroll'

export function sidebarToggler() {
  const [menu] = document.getElementsByClassName('js-sidebar')
  const [toggler] = document.getElementsByClassName('js-toggler')
  function checkSidebarActivity() {
    if (menu.classList.contains('is-active')) scrollLock()
    else scrollUnlock()
  }
  function openMenu() {
    menu.classList.toggle('is-active')
    toggler.classList.toggle('is-active')
    checkSidebarActivity()
  }
  if (menu) {
    toggler.addEventListener('click', openMenu)
  }
}
