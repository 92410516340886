import qs from 'qs'
import { createI18n } from '@/i18n'
import { eraseCookie, getCookie } from '@/js/resource/cookie'
import { createRouter } from './router'

export function createTakeTestApp({ Vue, I18n, Router }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, Router })

  const app = new Vue({
    i18n,
    router,

    created() {
      setTimeout(() => {
        const { search, hostname } = window.location
        const { ref } = qs.parse(search.replace(/\?/g, ''))

        if (ref) {
          if (getCookie('bpmRef')) {
            eraseCookie('bpmRef')
          }

          const date = (new Date(Date.now() + 86400e3)).toUTCString()

          document.cookie = `bpmRef=${ref}; path=/;  domain=${hostname}; expires=${date};`
        }
      }, 1000)
    },
  })

  return { app, i18n, router }
}
