<template lang="pug">
  include ../../mixins
  +b.form
    +b.g-row.--appearance_spaced.--align_end
      +b.g-cell.g-cols--12.--6-sm.--4-md.--3-lg.--4-xl.--3-mac(v-if="needSearch")
        tag(
          ref="search"
          :tag="$options.widgetsMap['search']"
          :parameters="parameters"
          :value="value['search']"
          :search-hint="searchHint"
          @input="updateValue('search', $event)"
        )
      +b.g-cell.g-cols--12.--6-sm.--4-md.--3-lg.--4-xl.--3-mac(
        v-for="(filter, key) in filters"
      )
        tag(
          :tag="$options.widgetsMap[filter.type]"
          :parameters="parameters"
          :value="preparedValue[filter.urlKey]"
          :filter-name="filter.urlKey"
          :filter="filter"
          @input="filter.type === 'DateRange' ? updateMultipleValues(filter.urlKey, $event) : updateValue(filter.urlKey, $event)"
        )
      slot
</template>

<script>
import UiMixin from '../mixins/UiMixin'

const AFTER_KEY = '_after'
const BEFORE_KEY = '_before'

export default {
  mixins: [UiMixin],

  props: [
    'value',
    'filters',
    'pagination',
    'needSearch',
    'searchHint',
  ],

  computed: {
    preparedValue() {
      return Object.keys(this.value).reduce((acc, k) => {
        if (k.includes(AFTER_KEY)) {
          const filterKey = k.replace(AFTER_KEY, '')
          if (!acc[filterKey]) acc[filterKey] = []
          this.$set(acc[filterKey], 0, this.value[k])
        } else if (k.includes(BEFORE_KEY)) {
          const filterKey = k.replace(BEFORE_KEY, '')
          if (!acc[filterKey]) acc[filterKey] = []
          this.$set(acc[filterKey], 1, this.value[k])
        } else {
          acc[k] = this.value[k]
        }
        return acc
      }, {})
    },
  },

  methods: {
    resetValues() {
      if (this.$refs.search.resetValue) {
        this.$refs.search.resetValue()
      }
    },
  },
}
</script>
