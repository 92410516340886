<template lang="pug">
  include ../mixins
  div
    ui-preloader(
      :class="{'is-active': isLoad}"
      loader-color="loader-wrapper__bg--accent-1"
      loader-class="loader--variant_absolute"
    )
    bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
      tag="p"
      color="white"
      size="md"
      weight="semi-bold"
    ) {{_("Запишись на безкоштовний урок")}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
      @keyup.enter="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm.pos--relative(v-for="input in formModel")
        template(v-if="input.view === 'input'")
          component(
            :is="input.component"
            :rules="input.rules"
            :type="input.type"
            :inputmode="input.type"
            :label-class="input.labelClass"
            :class-name="input.className"
            :placeholder="input.placeholder"
            v-model="formdata[input.model]"
            :required="input.required"
            :name="input.name"
            :mask="input.mask"
            :options="input.options"
            @keyup.enter="validateBeforeSubmit"
          )
        template(v-if="input.view === 'phone'")
          +b.stock-form-phone
            validation-provider(
              v-slot="{ errors }"
              :name="input.name"
              :rules="{ required: true, phone_is_valid: [phoneIsValid] }"
            )
              vue-phone-number-input(
                v-model="formdata[input.model]"
                :name="input.name"
                default-country-code='UA'
                @update="validatePhone"
                :translations="translations"
              )
              +b.control-hint.--variant_error(v-if="errors[0]") {{ errors[0] }}
      privacy-rules.ma.ma--bottom_sm(
        :rules-terms="objectItem.rules"
        :privacy="objectItem.privacy"
      )
      bem-button(
        width="full"
        block="ls-button"
        appearance="yellow-arrow"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('відіслати')}}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@/js/mixins/form.mixin.js'
import { contactResource } from '@/js/services/common.service.js'

export default {
  name: 'contact-form-modal',
  mixins: [FormMixin],
  components: {
    'privacy-rules': () => import('@/js/components/Forms/PrivacyRules'),
  },
  props: {
    objectItem: Object,
    formid: String,
  },
  data() {
    return {
      formdata: {
        name: '',
        email: '',
        phone: '',
        landingId: this.formid,
        isLoad: false,
      },
      formModel: [
        {
          component: 'control-input-pure',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          placeholder: this._('Вашe ім’я *'),
          model: 'name',
          required: true,
          name: 'name',
          options: null,
        },
        {
          component: 'control-input-pure',
          view: 'input',
          rules: 'email',
          type: 'email',
          className: 'form__input form__input--white',
          placeholder: this._('Ваш Email*'),
          model: 'email',
          name: 'email',
          required: true,
          options: null,
        },
        {
          component: 'control-tel-input-pure',
          view: 'phone',
          rules: 'required|min:10',
          type: 'number',
          className: 'form__input form__input--white',
          placeholder: this._('Ваш телефон *'),
          model: 'phone',
          required: true,
          name: 'phone',
          options: null,
        },
      ],
      phoneIsValid: false,
      translations: {
        countrySelectorLabel: this._('Оберіть країну'),
        countrySelectorError: this._('Оберіть країну'),
        phoneNumberLabel: this._('Ваш телефон *'),
        example: this._('Приклад вводу :'),
      },
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (window.isAuthenticated) {
          this.setUserInfo(nval)
        }
      },
    },
  },

  // mounted() {
  //   console.log('mounted')
  //   console.log(this.formid)
  // },

  methods: {
    setUserInfo(data) {
      const { info } = data

      this.formdata.phone = info.phone

      if ('company' === info.typeUser) {
        this.formdata.name = info.firstNameContact
      } else {
        this.formdata.name = info.firstName
      }
    },

    validatePhone(state) {
      this.phoneIsValid = state.isValid
    },

    async send(data) {
      // console.log('data', data)
      this.isLoad = true
      data.landingId = this.formid
      const copyData = await this.prepareData({ ...data })
      // console.log('copyData', copyData)
      return contactResource.execute({}, copyData)
        .then(res => {
          this.isLoad = false
          this.handleResponse({ res, modal: true })
        })
    },
  },
}
</script>
