<template lang='pug'>
  include ../mixins
  div
    +b.modal-close--video.BUTTON(@click="$emit('close')")
    +b.video
      +e.frame-wrapper
        +e.VIDEO.frame(autoplay="autoplay" controls="controls" preload="metadata")
          source(:src="objectItem.src" type="video/mp4")
</template>

<script>
export default {
  name: 'common-video-modal',

  props: {
    objectItem: Object,
  },

  methods: {},
}
</script>
