import { prettifyPhone } from '@/js/helpers/utils'

export const parseFileName = url => {
  const name = url.split('/').pop()

  return name
}

export const install = Vue => {
  Vue.filter('parseFileName', parseFileName)
  Vue.filter('prettifyPhone', prettifyPhone)
}

export default { install }
