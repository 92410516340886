import { pipe } from 'ramda'
import * as qs from 'querystring'
import {
  receiveResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
  raiseHttpErrorTransformer,
} from './transformer'
import { HEADERS_MIDDLEWERE } from './middleware'

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)
export const postResource = getResource
  .config('method', 'POST')
export const patchResource = postResource
  .config('method', 'PATCH')
export const deleteResource = getResource
  .config('method', 'DELETE')
export const putResource = postResource
  .config('method', 'PUT')
export const optionsResource = postResource
  .config('method', 'OPTIONS')
export function dynamicUrl(url) {
  return receiveResource
    .url(parameters => `${url}?${qs.stringify(parameters)}`)
    .middleware(jsonRequestMiddleware)
    .middleware(HEADERS_MIDDLEWERE)
}
export function createResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(makeTemplateGetter(template))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}
