<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{_("Изменение пароля")}}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm
      control-input-common(
        rules="required|min:8"
        ref='password'
        type="password"
        v-model="formdata.passwordOld"
        class-name="form__input form__input--space_right form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Введите текущий пароль')"
        :required="true"
        name="passwordOld"
      )
    +b.form.ma--bottom_sm
      control-input-common(
        rules="required|min:8"
        ref='password'
        type="password"
        v-model="formdata.password"
        class-name="form__input form__input--space_right form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Введите новый пароль')"
        :required="true"
        name="password"
      )
    +b.form.ma--bottom_sm
      control-input-common(
        rules="required|min:8|confirmed:password"
        ref='passwordConfirm'
        type="password"
        v-model="formdata.passwordConfirm"
        class-name="form__input form__input--space_right form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Введите новый пароль еще раз')"
        :required="true"
        name="passwordConfirm"
      )
    bem-button.de-text--size_2xs(
      width="full"
      block="ls-button"
      appearance="yellow"
      :disabled="isLoad"
      @event="validateBeforeSubmit()"
    ) {{_('Изменить пароль')}}
</template>

<script>
import { passwordChangeResource } from '@/js/services/auth.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    async send(data) {
      const copyData = await this.prepareData({ ...data })
      return passwordChangeResource.execute({}, copyData).then(res => {
        this.handleResponse({ res, modal: true })
      })
    },
  },
}
</script>
