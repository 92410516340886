<template>
  <autocomplete-multiselect
    v-bind="$attrs"
    v-on="$listeners"
    :multiple="false"
  />
</template>

<script>

import AutocompleteMultiselect from './AutocompleteMultiselectWidget'

export default {
  components: { AutocompleteMultiselect },
}
</script>
