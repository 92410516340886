<template lang="pug">
  vue-tel-input(
    v-model="val"
    :input-options="inputOptions"
    defaultCountry="UA"
    :autoFormat="false",
    mode="international"
    :valid-characters-only="true"
    @input="$emit('input', val)"
  )
</template>

<script>

export default {
  name: 'tel-input',
  props: {
    value: {},
    name: {},
    placeholder: {},
  },
  data() {
    return {
      val: null,
      inputOptions: {
        autocomplete: 'off',
        showDialCode: true,
        autofocus: false,
        minlength: 10,
        maxlength: 20,
        name: this.name || 'phone',
        required: true,
        type: 'tel',
        placeholder: this.placeholder || this._('Номер телефона'),
      },
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (!nval) {
          this.$nextTick(() => {
            this.val = '+380'
          })
        } else {
          this.val = nval.includes('+') ? nval : `+${nval}`
        }
      },
    },
  },
}
</script>
