// ios scroll
const body = document.querySelector('body')
// const app = document.getElementById('app')
// const content = document.getElementsByClassName('content-wrapper')[0]
let scrollPosition = 0
const zero = 0

export const scrollUnlock = () => {
  const app = document.getElementById('app')
  // const content = document.getElementsByClassName('content-wrapper')[0]
  console.log('scrollUnlock')
  // console.log('body', body)
  console.log('app', app)
  // console.log('content', content)
  body.classList.remove('scroll-lock')
  body.classList.add('scroll-unlock')
  // app.classList.remove('scroll-lock')
  // app.classList.add('scroll-unlock')
  // content.classList.remove('scroll-lock')
  // content.classList.add('scroll-unlock')
  window.scrollTo(zero, scrollPosition)
}

export const scrollLock = () => {
  const app = document.getElementById('app')
  // const content = document.getElementsByClassName('content-wrapper')[0]
  console.log('scrollLock')
  // console.log('body', body)
  console.log('app', app)
  // console.log('content', content)
  scrollPosition = window.pageYOffset
  body.classList.add('scroll-lock')
  body.classList.remove('scroll-unlock')
  // app.classList.add('scroll-lock')
  // app.classList.remove('scroll-unlock')
  // content.classList.add('scroll-lock')
  // content.classList.remove('scroll-unlock')
}
