import {
  createResource,
  postResource,
  getResource,
} from '@/js/resource/resource'

import { REVIEWS } from './paths'

export const reviewCatalogResource = createResource(
  REVIEWS.CATALOG, getResource
)

export const reviewFiltersResource = createResource(
  REVIEWS.FILTERS, getResource
)

export const reviewCreateResource = createResource(
  REVIEWS.CREATE, postResource
)
