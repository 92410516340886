export default {
  place: {
    ru: ['место', 'места', 'мест'],
    uk: ['місце', 'місця', 'місць'],
    en: ['place', 'places', 'places'],
  },
  month: {
    ru: ['месяц', 'месяца', 'месяцев'],
    uk: ['місяць', 'місяці', 'місяців'],
    en: ['month', 'months', 'months'],
  },
  week: {
    ru: ['неделя', 'недели', 'недель'],
    uk: ['тиждень', 'тижні', 'тижнів'],
    en: ['week', 'weeks', 'weeks'],
  },
}
