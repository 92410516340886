<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    +b.P.ls-text.--size_3xs.--size_sm-v2-xl.--weight_semi-bold(
      v-if="title"
    ) {{title}}
    +b.P.ls-text.--size_2xs-v2.--height_xl.ma--top_sm(
      v-if="text"
    ) {{text}}
    +b.ma.--top_sm
      +b.g-row.--justify_center
        +b.g-cell.g-cols--8
          +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_6(
            @click.prevent="$emit('close')"
          ) {{_('Понятно')}}
</template>

<script>
export default {
  name: 'text-modal',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>
