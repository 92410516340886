import LanguageControl from './LanguageControl'
import VideoBlock from './VideoBlock'
import UiSlider from './UiSlider'
import ClampField from './ClampField'

const UiUpload = () => import('./UiUpload')
const UiEditor = () => import('./UiEditor')
const UiCropper = () => import('./UiCropper')
const UiPreloader = () => import('./UiPreloader')
const OverflowField = () => import('./OverflowField')
const ProfileLink = () => import('./ProfileLink')
const ContentOverflow = () => import('./ContentOverflow')
const ShareLinks = () => import('./ShareLinks')
const LikeButton = () => import('./LikeButton')
const VideoWithTimeline = () => import('./VideoWithTimeline')
const ScrollTopButton = () => import('./ScrollTopButton')
const HijackButton = () => import('./HijackButton')
const UiCountdown = () => import('./UiCountdown')
const ClampFieldAdvanced = () => import('./ClampFieldAdvanced')

export default function UiElements(Vue) {
  Vue.component(UiSlider.name, UiSlider)
  Vue.component(LanguageControl.name, LanguageControl)
  Vue.component(VideoBlock.name, VideoBlock)
  Vue.component(ClampField.name, ClampField)
  Vue.component('ui-upload', UiUpload)
  Vue.component('ui-editor', UiEditor)
  Vue.component('ui-cropper', UiCropper)
  Vue.component('ui-preloader', UiPreloader)
  Vue.component('overflow-field', OverflowField)
  Vue.component('profile-link', ProfileLink)
  Vue.component('content-overflow', ContentOverflow)
  Vue.component('share-links', ShareLinks)
  Vue.component('like-button', LikeButton)
  Vue.component('video-with-timeline', VideoWithTimeline)
  Vue.component('scroll-top-button', ScrollTopButton)
  Vue.component('hijack-button', HijackButton)
  Vue.component('ui-countdown', UiCountdown)
  Vue.component('clamp-field-advanced', ClampFieldAdvanced)
}
