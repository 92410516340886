<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--top_3xs-xxl.ma--bottom_3sm.ma--right_2sm(
      tag="h1"
      size="md"
      color="white"
      weight="semi-bold"
    ) {{ _('Выберите группу') }}
    +b.g-row
      +b.g-cell.g-cols--12
        +b.form.ma--bottom_sm
          control-multiselect-common(
            rules="required"
            class="common-multiselect common-multiselect--light-3"
            label-class="form__label form__label--accent-1"
            :options="choices.groups"
            :allow-empty="false"
            label='title'
            v-model="formdata.group"
            :placeholder="_('Группа')"
            name="group"
            :required="true"
          )
            template(slot="noResult" slot-scope="{}")
              span {{ _("Элементы не найдены.") }}
            template(slot="noOptions" slot-scope="{}")
              span {{ _("Список пуст.") }}
    bem-text.ma--bottom_sm.ma--right_2sm(
      tag="p"
      size="2xs"
      color="white"
      height="xs"
    ) {{ _('Выберите, пожалуйста, интересующую Вас группу по которой хотите просматривать информацию во вкладках «Лента», «Уроки», «ДЗ»') }}
    bem-button(
      width="full"
      block="ls-button"
      appearance="yellow-arrow"
      @event="send"
    ) {{ _('Выбрать') }}
</template>

<script>
import FormMixin from '@/js/mixins/form.mixin'
import { setCookie, getCookie } from '@/js/resource/cookie'

const ROUTES_WITH_GROUP = [
  'profile:group:homework:list',
  'profile:group:lesson:list',
]

export default {
  name: 'group-list-modal',

  props: {
    objectItem: {},
  },

  mixins: [
    FormMixin,
  ],

  components: {
    'form-generator': () => import('@/js/components/Forms/FormGenerator'),
  },

  data() {
    return {
      formdata: {
        group: null,
      },
      choices: {
        groups: this.objectItem.groups,
      },
      userGroupId: null,
    }
  },

  mounted() {
    this.preselectGroup()
  },

  methods: {
    preselectGroup() {
      const [firstGroup] = this.choices.groups
      this.userGroupId = Number(getCookie('user_group_id'))
      if (this.userGroupId) {
        this.setGroup(this.userGroupId)
      } else {
        this.setGroup(firstGroup.id)
      }
    },

    setGroup(id) {
      this.formdata.group = this.choices.groups.find(el => el.id === id)
    },

    send() {
      this.$nextTick(() => {
        const { id, idStream } = this.formdata.group
        setCookie('user_group_id', id)
        setCookie('user_stream_id', idStream)
        const currentRouteName = this.$route.name
        if ('profile:feed:group' === currentRouteName) {
          if (idStream) {
            this.goToRoute(currentRouteName, { feed: idStream })
          } else {
            this.goToRoute('profile:group:lesson:list', { group: id })
          }
        } else if (ROUTES_WITH_GROUP.includes(currentRouteName)) {
          this.goToRoute(currentRouteName, { group: id })
        } else {
          window.location.reload()
        }
      })
    },

    goToRoute(currentRouteName, params) {
      const route = {
        name: currentRouteName,
        params,
      }
      const { href } = this.$router.resolve(route)
      window.location = href
    },
  },
}
</script>
