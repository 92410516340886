import {
  createResource,
  postResource,
} from '@/js/resource/resource'

import { PAYMENT_PERIOD } from './paths'

export const paymentPeriodPayResource = createResource(
  PAYMENT_PERIOD.PAY, postResource
)
