<template lang="pug">
  include ../../mixins
  control-multiselect-common(
    class="common-multiselect common-multiselect--light-3"
    label-class="form__label form__label--accent-1"
    :multiple='multiple',
    :options='filter.choices || []'
    v-model='checked'
    :name='filterName'
    :label='filterLabel'
    track-by='value'
    :placeholder='filter.label',
    :searchable="searchable"
    @input='submit'
    :maxHeight="224"
  )
</template>

<script>
export default {
  name: 'SimpleSelect',

  props: {
    value: {},
    filter: {},
    filterName: {},
    filterLabel: {
      default: 'label',
    },
    searchable: {
      default: false,
    },
    multiple: {
      default: false,
    },
  },

  data() {
    return {
      checked: '',
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      let val = value
      if (!value && false !== value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.choices
        .filter(el => val.find(v => v.value.toString() === el.value.toString()))
        .map(el => el.value.toString())
      return checked
    },

    normalizeFrom(value) {
      let val = value
      if (!value && false !== value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.choices
        .filter(el => val.find(v => v.toString() === el.value.toString()))
      return checked
    },
  },
}
</script>
