<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{ formdata.id ? _("Редактировать транзакцию") : _("Добавить транзакцию") }}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm
      control-multiselect-common(
        rules="required"
        class="common-multiselect common-multiselect--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Статус')"
        :options="choices.status"
        label='title'
        v-model="formdata.status"
        placeholder=""
        name="status"
        :required="true"
      )
        template(slot="noResult" slot-scope="{}")
          span {{ _("Элементы не найдены.") }}
        template(slot="noOptions" slot-scope="{}")
          span {{ _("Список пуст.") }}
    +b.form.ma--bottom_sm
      control-input-common(
        rules="numeric|required"
        type="number"
        class-name="form__input form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Сумма')"
        v-model="formdata.amount"
        name="amount"
      )
    +b.i-divider
    validation-provider(
      v-slot="{errors}"
      name="nonFieldErrors"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    validation-provider(
      v-slot="{errors}"
      name="order"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    +b.g-row.--appearance_spaced.--justify_end
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_accent-1-bordered.--width_full.--variant_6(
          @click.prevent="$emit('close')"
        ) {{_('Отмена')}}
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_6(
          @click.prevent="validateBeforeSubmit()"
        ) {{_('Сохранить')}}
</template>

<script>
import {
  transactionCreateResource,
  transactionUpdateResource,
  transactionStatusListResource,
} from '@/js/services/transaction.service'
import FormMixin from '@/js/mixins/form.mixin.js'
import SuccessModal from '@/js/components/Modals/SuccessModal'

export default {
  mixins: [FormMixin],

  props: {
    objectItem: {},
  },

  data() {
    return {
      formdata: {
        status: 'new',
        ...this.objectItem,
      },
      choices: {
        status: [],
      },
    }
  },

  created() {
    this.getStatuses()
  },

  methods: {
    getStatuses() {
      transactionStatusListResource.execute().then(res => {
        const { data: { items } } = res
        this.choices.status = items
        this.preselectStatus()
      })
    },

    preselectStatus() {
      const item = this.choices.status.find(el => {
        if (this.formdata.status === el.value) {
          return el
        }
        return false
      })
      this.formdata.status = item
    },

    prepareFormdata(data) {
      const formdata = JSON.parse(JSON.stringify(data))
      formdata.status = formdata.status.value
      return formdata
    },

    async send(data) {
      const formdata = this.prepareFormdata(data)

      let resource = transactionCreateResource
      const params = {}

      if (formdata.id) {
        params.id = formdata.id
        resource = transactionUpdateResource
      }

      return resource.execute(params, formdata).then(() => {
        this.$emit('close')
        let title = this._('Транзакция успешно создана')
        if (formdata.id) {
          title = this._('Транзакция успешно обновлена')
        }
        this.showModal(title)
      })
    },

    showModal(title) {
      this.$modal.show(SuccessModal, {
        title,
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_sm modal--color_white modal--border',
      }, {
        'before-close': () => {
          window.location.reload()
        },
      })
    },
  },
}
</script>
