import { isInViewport } from '@/js/utils/inViewport'

export default {
  bind() {
    document.addEventListener('DOMContentLoaded', () => {
      const items = document.querySelectorAll('.js-section')
      const links = document.querySelectorAll('.js-highlight-link')
      const highlight = () => {
        items.forEach(el => {
          if (isInViewport(el)) {
            links.forEach(link => {
              const sectionId = el.getAttribute('id')
              const linkId = link.getAttribute('data-id')

              link.classList.remove('is-active')

              if (linkId === sectionId) {
                link.classList.add('is-active')
              }
            })
          }
        })
      }

      highlight()

      window.addEventListener('scroll', () => {
        highlight()
      })
    })
  },
}
