<template>
  <resource-loader-controller
    :resource="resource"
    v-slot="{ receive, loading, result }"
  >
    <delayed-executor-controller
      :delay="300"
      :receiver="receive"
      v-slot="{ receive: delayedReceive }"
    >
      <view-component
        v-bind="$attrs"
        :value="value"
        @input="submit"
        :filter="filter"
        :result="result"
        :loading="loading"
        :receive="delayedReceive"
        :rules="rules"
        :label="label"
        :track-by="trackBy"
        :label-is-visible="labelIsVisible"
        :placeholder-is-visible="placeholderIsVisible"
      />
    </delayed-executor-controller>
  </resource-loader-controller>
</template>

<script>
import { createResource } from '@/js/resource/resource'
import ViewComponent from './Component'

export default {
  name: 'filter-autocomplete-multiselect-widget-controller',
  props: {
    value: {},
    filter: {},
    url: {},
    rules: {},
    label: {
      default: 'label',
    },
    trackBy: {
      default: 'value',
    },
    labelIsVisible: {
      default: false,
    },
    placeholderIsVisible: {
      default: false,
    },
    multiple: {
      default: true,
    },
  },

  components: {
    ViewComponent,
  },

  computed: {
    resource() {
      const { choiceUrl: url } = this.filter

      return createResource(`${url}{?search,page}`)
    },
  },

  methods: {
    submit(val) {
      this.$emit('input', val)
    },
  },
}

</script>
