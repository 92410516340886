import {
  createResource,
  postResource,
  // getResource,
} from '@/js/resource/resource'

import { COMMON, LIKE } from './paths'

export const contactResource = createResource(
  COMMON.CONTACT, postResource
)

export const locationResource = createResource(
  COMMON.LOCATION, postResource
)

export const likeArticleResource = createResource(
  LIKE.ARTICLE, postResource
)

export const likeEventResource = createResource(
  LIKE.EVENT, postResource
)
