/* eslint-disable max-classes-per-file */

export class UserBase {
  determined = false;

  info = {};
}

export class User extends UserBase {
  constructor(info, determined = true) {
    super();

    this.determined = determined;
    this.info = info || this.info;
  }
}
