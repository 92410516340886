<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{ _("Плохое поведение ученика") }}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm
      control-textarea-common(
        rules="required|max:1000"
        class-name="form__textarea form__textarea--light-3 form__textarea--height_sm"
        label-class="form__label form__label--accent-1"
        :input-label="_('Комментарий родителю')"
        v-model="formdata.badBehaviorComment"
        placeholder=""
        name="badBehaviorComment"
      )
    +b.i-divider
    validation-provider(
      v-slot="{errors}"
      name="nonFieldErrors"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    +b.g-row.--appearance_spaced.--justify_end
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_accent-1-bordered.--width_full.--variant_6(
          @click.prevent="$emit('close')"
        ) {{_('Отмена')}}
      +b.g-cell.g-cols--6.--4-sm.--3-lg.--narrow-xl
        +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_6(
          @click.prevent="validateBeforeSubmit()"
        ) {{_('Сохранить')}}
</template>

<script>
import {
  behaviorSetResource,
  behaviorRetrieveResource,
} from '@/js/services/homework.service'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  props: {
    objectItem: {},
  },

  data() {
    return {
      formdata: {
        idPointHt: this.objectItem.id,
        badBehaviorComment: null,
      },
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      const params = { id: this.objectItem.id }

      behaviorRetrieveResource.execute(params).then(res => {
        const { data: { item } } = res
        this.formdata.badBehaviorComment = item.badBehaviorComment
      })
    },

    async send(data) {
      return behaviorSetResource.execute({}, data).then(res => {
        this.$emit('close')
        this.handleResponse({ res, modal: true })
      })
    },
  },
}
</script>
