<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
    @keyup.enter="validateBeforeSubmit"
  )
    form-generator(
      :options="options"
      :formdata="formdata"
      :form-model="formModel"
    )
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols--12.--6-sm.--12-xl.--6-xxl
        +b.form.pos.--relative.ma.--bottom_sm
          control-multiselect-common(
            rules="required"
            :required="true"
            class="common-multiselect common-multiselect--white"
            label-class="form__label form__label--white"
            :input-label="labels.school.label"
            :options="options.school"
            label='title'
            v-model="formdata.school"
            :placeholder="labels.school.placeholder"
            name="school"
          )
            template(slot="noResult" slot-scope="{}")
              span {{ _("Элементы не найдены.") }}
            template(slot="noOptions" slot-scope="{}")
              span {{ _("Список пуст.") }}
      +b.g-cell.g-cols--12.--6-sm.--12-xl.--6-xxl(
        v-if="needShowChilds"
      )
        +b.form.pos.--relative.ma.--bottom_sm
          control-multiselect-common(
            rules=""
            :required="false"
            class="common-multiselect common-multiselect--white"
            label-class="form__label form__label--white"
            :input-label="labels.child.label"
            :options="options.childs"
            label='title'
            v-model="formdata.idChild"
            :placeholder="labels.child.placeholder"
            :custom-label="childLabel"
            name="child"
          )
            template(slot="noResult" slot-scope="{}")
              span {{ _("Элементы не найдены.") }}
            template(slot="noOptions" slot-scope="{}")
              span {{ _("Список пуст.") }}
      +b.g-cell.g-cols--12
        control-textarea-common(
          rules="max:1000"
          class-name="form__textarea form__textarea--white form__textarea--height_md"
          label-class="form__label form__label--white"
          :input-label="labels.message.label"
          v-model="formdata.comment"
          placeholder=""
          name="comment"
        )
    privacy-rules.ma--bottom_sm(
      :rules-terms="objectItem.rules"
      :privacy="objectItem.privacy"
    )
    +b.error-text.ma--bottom_sm.--top_sm.P(
      v-for="error in nonFieldErrors"
      v-if="nonFieldErrors.length > 0"
    ) {{ error }}
    row(justify="end")
      cell(cols="12 6-sm")
        bem-button(
          width="full"
          block="ls-button"
          appearance="yellow-arrow"
          :disabled="isLoad"
          @event="validateBeforeSubmit()"
        ) {{_('відіслати')}}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@/js/mixins/form.mixin.js'
import { createGroupRequestResource } from '@/js/services/groups.service'
import { getCookie } from '@/js/resource/cookie'
import UserInfoMixin from '@/js/mixins/userInfo.mixin.js'

const {
  isChild,
  isMainWithChilds,
  userType,
} = window

export default {
  name: 'course-form',

  mixins: [
    FormMixin,
    UserInfoMixin,
  ],

  components: {
    'form-generator': () => import('@/js/components/Forms/FormGenerator'),
    'privacy-rules': () => import('@/js/components/Forms/PrivacyRules'),
  },

  props: {
    objectItem: Object,
    schools: Array,
  },

  data() {
    return {
      schoolIdFromCookie: getCookie('school_id'),
      isChild,
      isMainWithChilds,
      userType,
      formdata: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        comment: '',
        school: '',
      },
      options: {
        school: this.schools,
        childs: [],
      },
      formModel: [
        {
          cols: '12 6-sm',
          component: 'control-input',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          labelClass: 'form__label form__label--white',
          inputLabel: this._('Вашe имя'),
          model: 'name',
          required: true,
          name: 'name',
        },
        {
          cols: '12 6-sm',
          component: 'control-input',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          labelClass: 'form__label form__label--white',
          inputLabel: this._('Вашe фамилия'),
          model: 'surname',
          required: true,
          name: 'surname',
        },
        {
          cols: '12 6-sm',
          component: 'control-tel-input',
          view: 'input',
          rules: 'required|min:10',
          type: 'number',
          className: 'form__input form__input--white',
          labelClass: 'form__label form__label--white',
          inputLabel: this._('Номер телефона'),
          model: 'phone',
          required: true,
          name: 'phone',
        },
        {
          cols: '12 6-sm',
          component: 'control-input',
          view: 'input',
          rules: 'email',
          type: 'email',
          className: 'form__input form__input--white',
          labelClass: 'form__label form__label--white',
          inputLabel: this._('Email'),
          model: 'email',
          required: true,
          name: 'email',
          readonly: true,
        },
      ],
      labels: {
        message: {
          label: this._('Комментарий'),
        },
        school: {
          label: this._('Школа'),
          placeholder: this._('Выберите школу'),
        },
        child: {
          label: this._('Ребенок/сотрудник'),
          placeholder: this._('Выберите ребенка/сотрудника'),
        },
      },
      needShowChilds: false,
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },
  mounted() {
    console.log('objectItem#########', this.objectItem)
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler() {
        this.preselectUserSchool()
        if (window.isAuthenticated) {
          this.setUserInfo()
        }
      },
    },
  },
  mounted() {
    console.log('course form!!!!!!!!!')
  },
  methods: {
    setUserInfo() {
      const { info } = this.user
      console.log('user info', info)
      console.log('this.isChild', this.isChild)
      console.log('this.isMainWithChilds', this.isMainWithChilds)
      if (this.isChild) {
        console.log('is child')
        this.formdata.name = info.firstName
        this.formdata.surname = info.lastName
        this.formdata.phone = info.phone
        this.formdata.email = info.mainUserEmail
      } else if (this.isMainWithChilds) {
        console.log('is main user parent')
        this.formdata.phone = info.phone
        this.formdata.email = info.email
      }
      // this.formdata.phone = info.phone
      // this.formdata.email = info.email
      // if ('company' === info.typeUser) {
      //   this.formdata.name = info.firstNameContact
      //   this.formdata.surname = info.lastNameContact
      // } else {
      //   this.formdata.name = info.firstName
      //   this.formdata.surname = info.lastName
      // }

      this.checkUserType()
    },

    checkUserType() {
      const { info } = this.user
      // const { typeUser } = info
      // const isParent = 'parent' === typeUser
      // const isCompany = 'company' === typeUser

      // this.needShowChilds = isCompany || isParent
      this.needShowChilds = this.isMainWithChilds
      if (this.isMainWithChilds) {
        this.options.childs = info.childs
      }
    },

    childLabel({ firstName, lastName }) {
      return `${firstName} ${lastName}`
    },

    preselectUserSchool() {
      const userSchoolId = this.user.info.school
      const [firstSchool] = this.schools
      const schoolByUser = this.getSchoolById(userSchoolId)
      const schoolFromCookie = this.getSchoolById(this.schoolIdFromCookie)
      if (!schoolByUser && !schoolFromCookie) {
        this.formdata.school = firstSchool
      } else if (schoolByUser) {
        this.formdata.school = schoolByUser
      } else if (schoolFromCookie) {
        this.formdata.school = schoolFromCookie
      }
    },

    getSchoolById(id) {
      return this.schools.find(el => el.id === Number(id))
    },

    async send(data) {
      data.group = this.objectItem.groupId
      const copyData = await this.prepareData({ ...data })
      return createGroupRequestResource.execute({}, copyData).then(res => {
        this.handleResponse({ res, modal: true })
        this.$nextTick(() => {
          this.preselectUserSchool()
          if (window.isAuthenticated) {
            this.setUserInfo()
          }
        })
        // eslint-disable-next-line
        ga('send', 'event', 'form', 'submit', 'group_enquiry')
      })
    },
  },
}
</script>
