import dictionary from './dictionary'

const lang = window.language
function handleCase(amount, word) {
  const [first, second, third] = dictionary[word][lang]
  const n1 = Number(amount)
  const n2 = Number(amount) % 10
  if (10 < n1 && 20 > n1) return third
  if (1 < n2 && 5 > n2) return second
  if (1 === n2) return first
  return third
}

export default {
  bind(element, binding) {
    const amount = binding.value
    const word = binding.arg
    element.innerHTML = handleCase(amount, word)
  },
}
