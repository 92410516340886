<template lang="pug">
include ../mixins
+b.ls-card.--color_white.--border_3sm-adaptive.pos--relative
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  +b.ls-card.--space_3sm
    bem-text(
      tag="p"
      size="md"
      weight="semi-bold"
    ) {{_("Оценить задание")}}
  check-homework-form(
    :task-id="objectItem.taskId"
    :reloadEvent="objectItem.reloadEvent"
    :need-display-teacher="false"
    :event-key="objectItem.eventKey"
    :info="objectItem.info"
  )
</template>

<script>
export default {
  props: {
    objectItem: {},
  },

  components: {
    'check-homework-form': () => import('@/js/components/Profile/modules/homework/components/CheckForm'),
  },
}
</script>
