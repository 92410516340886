<template lang='pug'>
  include ../mixins
  div
    +b.modal-close--video.BUTTON(@click="$emit('close')")
    +b.video
      +e.frame-wrapper
        +e.frame.IFRAME(
          :src='getVideo(objectItem.src)'
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen height='100%'
        )
</template>

<script>
import { getVideoId } from '@utils/video'

export default {
  name: 'video-modal',
  props: {
    objectItem: Object,
  },
  methods: {
    getVideoPreview(url) {
      const videoPreview = `//img.youtube.com/vi/${getVideoId(url)}/sddefault.jpg`
      return videoPreview
    },
    getVideo(el) {
      const item = `//www.youtube.com/embed/${getVideoId(el)}?autoplay=1`
      return item
    },
  },
}
</script>
