<template lang='pug'>
  include ../../mixins
  +b.cabinet-filter(v-if="Object.keys(filters).length || needSearch")
    +e.wrapper
      ui-preloader(
        :class="{'is-active': loading}"
        loader-color='loader-wrapper__bg--accent-1'
      )
      +e.content
        filter-ui(
          ref="filterUi"
          :disabled="loading"
          :value="prepared"
          :filters="filters"
          :pagination='pagination'
          :needSearch="needSearch"
          :searchHint="searchHint"
          @input="handleInput"
        )
          +b.g-cell.g-cols
            +b.g-row.--align_center.--appearance_spaced
              +b.g-cell.g-cols.--auto-xs
                +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_6(
                  @click.prevent="handleInputFilter(prepared)"
                ) {{ _('Фильтровать') }}
              +b.g-cell
                +b.i-link.--size_2xs-v2.--color_accent-1(
                  @click.prevent="handleInputFilter({});resetValues()"
                )
                  +e.icon.--size_3sm
                    +b.I.ls-icon.--type_refresh
                  +e.SPAN.text {{ _("Очистить фильтр") }}
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
