import TeachersCatalog from './TeachersCatalog'

const GroupsCatalog = () => import('./GroupsCatalog')
const ReviewsCatalog = () => import('./ReviewsCatalog')
const NewsCatalog = () => import('./NewsCatalog')
const VideoReviewsCatalog = () => import('./VideoReviewsCatalog')

export default function install(Vue) {
  Vue.component(TeachersCatalog.name, TeachersCatalog)
  Vue.component('GroupsCatalog', GroupsCatalog)
  Vue.component('ReviewsCatalog', ReviewsCatalog)
  Vue.component('NewsCatalog', NewsCatalog)
  Vue.component('VideoReviewsCatalog', VideoReviewsCatalog)
}
