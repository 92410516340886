<template lang="pug">
  date-picker.pointer(
    v-model="val"
    :valueType="valueType"
    :format='format'
    :placeholder="placeholder"
    :disabled-date="disabledDate"
    :lang="lang"
    :range="range"
    range-separator=" - "
    :type="pickerType"
    @input="$emit('update-date', val)"
    @change="$emit('update-date', val)"
  )
</template>

<script>
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'

import DatePicker from 'vue2-datepicker'

export default {
  name: 'date-pick',
  components: {
    DatePicker,
  },
  props: {
    format: String,
    valueType: String,
    value: {},
    placeholder: String,
    range: Boolean,
    disabledDate: Function,
    pickerType: {
      default: 'date',
      type: String,
    },
  },
  created() {
    DatePicker.locale(window.language)
  },
  data() {
    return {
      val: this.value,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    }
  },
  watch: {
    value: {
      deep: true,
      handler(nval) {
        this.val = nval
      },
    },
  },
}
</script>
