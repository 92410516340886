import Vue from 'vue'

function RenderComponent(template) {
  const renderer = Vue.compile(template)
  return {
    render: renderer.render,
    staticRenderFns: renderer.staticRenderFns,
  }
}

Vue.component('renderer', {
  render(h) {
    if ('object' === typeof this.result) {
      if (this.dataRes) {
        return h(
          'component', {
            is: this.component,
            props: {
              dataResult: this.dataRes,
            },
          }
        )
      }
      return h('div', this.$slots.default)
    }
    if (this.result) {
      return h(
        'component', {
          is: this.component,
          on: {
            renderer: event => {
              this.$emit('renderer-event', event)
            },
          },
        }
      )
    }
    return h('div', this.$slots.default)
  },
  props: ['result', 'dataRes', 'classRender'],
  data() {
    return {
      component: '',
      classItem: this.classRender,
      False: false,
      True: true,
      None: undefined,
    }
  },
  watch: {
    result: {
      handler(nval) {
        if (nval) {
          if ('object' === typeof nval) {
            this.component = nval.val
          } else {
            this.component = RenderComponent(`<div class="${this.classItem}">${nval}</div>`)
          }
        }
      },
      immediate: true,
    },
  },
})
