import { disableAnimation } from './disable-animation'
import { setTableWrapper } from './table-wrap'
import { sidebarToggler } from './sidebar'
import { toggleSeoText } from './seo-text'
import { anchors } from './anchors'
import { disableScrollToNumbInputs } from './disable-scroll-to-numb-inputs'

document.addEventListener('DOMContentLoaded', () => {
  disableAnimation()
  setTableWrapper()
  sidebarToggler()
  toggleSeoText()
  anchors()
  disableScrollToNumbInputs()
}, { passive: true })
