import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'

import { getCookie } from './cookie'

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRFToken': getCookie('csrftoken'),
  'Accept-Language': window.language,
}))
